import { Modal } from '@control-tower/aerq-ui-library'
import { InputModalContent } from 'components/InputModalContent'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useCopySurveyMutation } from 'services/surveyApi'
import { wrapInErrorHandler } from 'utils'

interface DuplicateSurveyModalProps {
    id: string
    initialInput: string
    isDuplicateSurveyModalOpen: boolean
    hideDuplicateSurveyModal: () => void
}

export const DuplicateSurveyModal = ({
    id,
    initialInput,
    isDuplicateSurveyModalOpen,
    hideDuplicateSurveyModal,
}: DuplicateSurveyModalProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [copySurvey, { isLoading: isUpdating }] = useCopySurveyMutation()
    const [error, setError] = useState<string | undefined>(undefined)

    const handleSubmit = async (technicalName: string) => {
        wrapInErrorHandler(
            async () => {
                const surveyData = {
                    technicalName,
                }
                const result = await copySurvey({ id, surveyData }).unwrap()
                navigate(`/edit-survey/${result._id}`)
                hideDuplicateSurveyModal()
            },
            t,
            setError
        )
    }

    return (
        <Modal
            open={isDuplicateSurveyModalOpen}
            closeBtn={true}
            handleModalState={hideDuplicateSurveyModal}
            maxWidth={600}
            children={
                <InputModalContent
                    initialInput={initialInput}
                    isUpdating={isUpdating}
                    handleSubmit={handleSubmit}
                    modalTitle={t('action.duplicateSurvey')}
                    inputHelperText={t('title.provideTitle')}
                    inputLabel={t('title.internalTitle')}
                    actionButtonLabel={t('action.createASurvey')}
                    isError={!!error}
                    errorMessage={error}
                />
            }
        />
    )
}
