import { Label } from '@control-tower/aerq-ui-library'
import { useTranslation } from 'react-i18next'

import { IDetailedConflictMap } from '../../../../types/interfaces/SurveyConflict'
import styles from './RuleManifestConflictResolutionModal.module.css'

export interface IConflictCounterLabelProps {
    usedConflicts: IDetailedConflictMap | undefined
}
export const ConflictCounterLabel = ({ usedConflicts }: IConflictCounterLabelProps) => {
    const { t } = useTranslation()

    return (
        <div className={styles.conflictCounterContainer}>
            <Label
                title={`${Object.keys(usedConflicts?.surveyIdToFlightNumberMap || {}).length} ${t('conflictResolutionModal.conflicts')}`}
                type="gray"
                color="500"
                lineHeight="28px"
                variant="h3"
                className={styles.conflictCounterLabel}
            />
            <Label
                title={t('conflictResolutionModal.resolveConflictsBeforeContinuing')}
                type="grey"
                color="900"
                lineHeight="28px"
                variant="subtitle1"
                className={styles.conflictCounterMessageLabel}
            />
        </div>
    )
}
