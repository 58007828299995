import { getToken } from '@control-tower/aerq-navigation-library'
import { exportSurveyFilename } from 'config/constants'
import { KeycloakService } from 'services/KeycloakService'

export const exportSurvey = async (surveyId: string) => {
    try {
        const keycloakService = KeycloakService.getInstance()
        const keycloakInstance = keycloakService.getKeycloakInstance()
        const authHeader = await getToken(keycloakInstance)

        const response = await fetch(`/api/v1/survey/${surveyId}/export`, {
            headers: {
                Authorization: authHeader?.Authorization,
                'CT-ProfileId': keycloakService.getCtProfileId(),
            },
        })

        if (!response?.ok) {
            const parsedError = await response.json()
            throw new Error(parsedError.errorCode)
        }

        const surveyFileName = response.headers.get(exportSurveyFilename) || surveyId
        const data = await response.blob()

        return { data, surveyFileName }
    } catch (e: any) {
        throw new Error(e.message)
    }
}
