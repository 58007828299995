import { useTranslation } from 'react-i18next'
import { TSurveyStatus } from 'types'
import { assertNever } from 'utils'

/**
 * Calculate tab title based on survey status for listing page
 *
 * @param status
 * @param count
 * @returns
 */
export const useTabTitle = (status: TSurveyStatus, count: number): string => {
    const { t } = useTranslation()
    switch (status) {
        case 'active':
            return `${count}  ${t('basic.activeSurveys')}`
        case 'draft':
            return `${count} ${t('basic.drafts')}`
        case 'archived':
            return `${count}  ${t('basic.archivedSurveys')}`
        default:
            return assertNever(status)
    }
}
