import { Modal } from '@control-tower/aerq-ui-library'
import { ModalContent } from 'components/ModalContent'
import { SnackbarOrigin, useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useArchiveSurveyMutation } from 'services/surveyApi'
import { customAnchor, customSuccessSnackbar } from 'utils/customSnackbarContent'

interface ArchiveSurveyModalProps {
    id: string
    isOpen: boolean
    hideModal: () => void
}

export const ArchiveSurveyModal = ({ id, isOpen, hideModal }: ArchiveSurveyModalProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [archiveSurvey, { isLoading, isError, error }] = useArchiveSurveyMutation()
    const { enqueueSnackbar } = useSnackbar()

    const handleSubmit = async () => {
        const result = await archiveSurvey(id).unwrap()
        navigate(`/edit-survey/${result._id}`)
        enqueueSnackbar(t('success.archiveSuccess'), {
            content: customSuccessSnackbar,
            anchorOrigin: customAnchor as SnackbarOrigin,
        })
    }

    return (
        <Modal
            open={isOpen}
            closeBtn={true}
            handleModalState={hideModal}
            maxWidth={600}
            children={
                <ModalContent
                    handleSubmit={handleSubmit}
                    modalTitle={t('basic.modalArchive')}
                    description={t('basic.descriptionArchive')}
                    actionButtonLabel={t('action.archiveSurvey')}
                    isSubmitDisabled={isLoading}
                    //@ts-ignore
                    errorMessage={error?.data?.message || t('error.wentWrongError')}
                    isError={isError}
                />
            }
        />
    )
}
