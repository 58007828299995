import CircularProgress from '@mui/material/CircularProgress'

/**
 * Pure spinner without any extra spacing
 *
 * Inherits AERQ's Primary color from ThemeProvider, so its already styled as expected.
 */
export const Spinner = () => {
    return <CircularProgress />
}
