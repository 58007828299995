import { ContentContainerTemplate, Label, Paginator, SubMenu, Table, TableControls } from '@control-tower/aerq-ui-library'
import { Spinner } from 'components/spinner'
import { useCallback } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { TSurveyStatus } from 'types'
import { ISurveyListItem } from 'types/interfaces/ISurveyListItem'
import { ISurveyListMeta } from 'types/interfaces/ISurveyListMeta'

import { NoResult } from './components/NoResult'
import { NoSurveys } from './components/NoSurveys'
import { useColumnConfig } from './hooks/ColumnConfig'
import { useFilterConfig } from './hooks/useFilterConfig'
import { useMenuConfig } from './hooks/useMenuConfig'
import { useOrderConfig } from './hooks/useOrderConfig'
import { useTabTitle } from './hooks/useTabTitle'
import { ListingHeader } from './ListingHeader'
import styles from './SurveyListing.module.css'

export interface SurveylistingProps {
    title: string
    surveyCount: ISurveyListMeta['surveyCount']
    surveyList: ISurveyListItem[]
    activePage: number
    resultsPerPage: number
    status: TSurveyStatus
    isFetching: boolean
    onSurveySearch: (e: React.ChangeEvent<HTMLInputElement>) => void
    onTabClick: (e: any) => void
    onOrderByClick: (e: any) => void
    onFilterByClick: (e: any) => void
    searchTerm: string
    currentCount: number
}

export function SurveyListing({
    title,
    surveyCount,
    surveyList,
    activePage,
    resultsPerPage,
    status,
    isFetching,
    onSurveySearch,
    onTabClick,
    onOrderByClick,
    onFilterByClick,
    searchTerm = '',
    currentCount,
}: SurveylistingProps): JSX.Element {
    const navigate = useNavigate()
    const location = useLocation()
    const paginate = useCallback(
        (clickedPage: any) => {
            navigate(`${location.pathname}?p=${clickedPage}`)
        },
        [navigate, location]
    )
    const columnConfig = useColumnConfig(status)
    const menuConfig = useMenuConfig(surveyCount, status)
    const orderConfig = useOrderConfig()
    const filterConfig = useFilterConfig()
    const tabTitle = useTabTitle(status, currentCount)
    const hasSurvey = !!surveyList?.length

    return (
        <ContentContainerTemplate className="contentContainer">
            <ListingHeader title={title} />

            {/* @ts-ignore */}
            <SubMenu onMenuItemClick={onTabClick} location={location} Link={Link} config={menuConfig} height={64} />

            <Label title={tabTitle} variant="h2" className={styles.statusLabel} />
            <TableControls
                filterItems={filterConfig}
                onFilterBy={onFilterByClick}
                onSearch={onSurveySearch}
                searchWord={searchTerm}
                onOrderBy={onOrderByClick}
                orderItems={orderConfig}
            />

            <div>
                {hasSurvey ? (
                    isFetching ? (
                        <div className={styles.spinnerContainer}>
                            <Spinner />
                        </div>
                    ) : (
                        <>
                            <Table
                                columns={columnConfig}
                                getRowData={({ index }) => surveyList[index]}
                                rowCount={surveyList.length}
                                hideHeader={false}
                                onRowClick={({ rowData }) => {
                                    navigate(`/edit-survey/${rowData._id}`)
                                }}
                            />
                            <div className={styles.paginationWrapper}>
                                <Paginator
                                    prevLabel="prev"
                                    nextLabel="next"
                                    page={activePage}
                                    resultsNum={currentCount}
                                    resultsPerPage={resultsPerPage}
                                    onClickPage={paginate}
                                    baseURL=""
                                    jumpToSectionName=""
                                />
                            </div>
                        </>
                    )
                ) : searchTerm !== '' ? (
                    <NoResult searchTerm={searchTerm} />
                ) : (
                    <NoSurveys status={status} />
                )}
            </div>
        </ContentContainerTemplate>
    )
}
