import { useTranslation } from 'react-i18next'
import { SurveyType } from 'types/enums/SurveyType'

type FilterConfig = {
    name: string
    options: {
        value: SurveyType | null
        label: string
    }[]
}[]

export const useFilterConfig = (): FilterConfig => {
    const { t } = useTranslation()

    return [
        {
            name: 'surveyType',
            options: [
                { label: t('surveyType.allType'), value: null },
                { label: t('surveyType.fullBlown'), value: SurveyType.fullBlown },
                { label: t('surveyType.rating'), value: SurveyType.rating },
                { label: t('surveyType.onboarding'), value: SurveyType.onboarding },
            ],
        },
    ]
}
