import { TSurveyStatus } from 'types'
import { assertNever } from 'utils'

export const useNoSurveyText = (status: TSurveyStatus): string => {
    switch (status) {
        case 'active':
            return 'basic.noActiveSurvey'
        case 'draft':
            return 'basic.noDraftSurvey'
        case 'archived':
            return 'basic.noArchivedSurvey'
        default:
            return assertNever(status)
    }
}
