import { configureStore } from '@reduxjs/toolkit'
import { configApi } from 'services/configApi'
import { ruleManifestApi } from 'services/ruleManifestApi'
import { surveyApi } from 'services/surveyApi'

import sortKeyReducer from './sortKeySlice'

export const store = configureStore({
    reducer: {
        sortKey: sortKeyReducer,
        // Add the generated reducer as a specific top-level slice
        [surveyApi.reducerPath]: surveyApi.reducer,
        [configApi.reducerPath]: configApi.reducer,
        [ruleManifestApi.reducerPath]: ruleManifestApi.reducer,
    },
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(surveyApi.middleware, configApi.middleware, ruleManifestApi.middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
// setupListeners(store.dispatch)
