import type { KeycloakInstance } from 'keycloak-js'

export class KeycloakService {
    private static _instance: KeycloakService
    private keycloakInstance: KeycloakInstance | null = null
    private ctProfileId: string = ''

    private constructor() {}

    public static getInstance() {
        if (!KeycloakService._instance) {
            KeycloakService._instance = new KeycloakService()
        }

        return KeycloakService._instance
    }

    public init(keycloakInstance: KeycloakInstance, ctProfileId: string) {
        this.keycloakInstance = keycloakInstance
        this.ctProfileId = ctProfileId
    }

    public getKeycloakInstance() {
        return this.keycloakInstance
    }

    public getCtProfileId() {
        return this.ctProfileId
    }
}
