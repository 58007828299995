/* eslint-disable import/no-extraneous-dependencies */
import 'survey-core/defaultV2.min.css'
import 'survey-core/survey.i18n'
import 'survey-creator-core/survey-creator-core.min.css'
import 'survey-creator-core/survey-creator-core.i18n'

import { usePreferencesState } from '@control-tower/aerq-navigation-library/dist/auth/state/Preferences/context'
import { SnackbarOrigin } from '@mui/material'
import { LoadingContainer } from 'components/loadingContainer'
import { useSnackbar } from 'notistack'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetSurveyQuery, useUpdateSurveyDefinitionMutation } from 'services/surveyApi'
import { surveyLocalization } from 'survey-core'
import { ICreatorOptions, localization } from 'survey-creator-core'
import { settings, SurveyCreator } from 'survey-creator-react'
import { SurveyType } from 'types/enums/SurveyType'
import { supportedSurveyJsLocals, SurveyCreatorUtils } from 'utils'
import { customAnchor, customErrorSnackbar } from 'utils/customSnackbarContent'

import { SurveyJsCreator } from './SurveyJsCreator'
import { errorCodeToMessage } from './utils/errorMessageHelper'

surveyLocalization.supportedLocales = supportedSurveyJsLocals

function sanitizeTranslationFor(obj: any, lng: string) {
    Object.keys(obj).forEach((key: any) => {
        switch (typeof obj[key]) {
            case 'object': {
                sanitizeTranslationFor(obj[key], lng)
                break
            }
            case 'string': {
                if (key === lng) {
                    delete obj[key]
                }
                break
            }
        }
    })
}

export const AERQSurveyCreator = () => {
    const { t } = useTranslation()
    const { preferences } = usePreferencesState()
    const { language } = preferences
    localization.currentLocale = language.toLowerCase()

    let navigate = useNavigate()
    const { id }: { id?: string } = useParams()
    const surveyId = id || ''
    const { data, error, isLoading, isFetching } = useGetSurveyQuery(surveyId)
    const [updateSurvey, { isError, error: updateError }] = useUpdateSurveyDefinitionMutation()
    const { enqueueSnackbar } = useSnackbar()

    if (data && data.status !== 'draft') navigate('/home')

    useEffect(() => {
        if (isError) {
            // @ts-ignore
            enqueueSnackbar(t(errorCodeToMessage(updateError?.data?.errorCode)), {
                content: customErrorSnackbar,
                anchorOrigin: customAnchor as SnackbarOrigin,
            })
        }
        // @ts-ignore
    }, [isError, enqueueSnackbar, t, updateError?.data?.errorCode])

    const creator = useMemo(() => {
        const surveyType = data?.surveyTypeConfig.surveyType
        let tempCreatorInstanceJSON: any = null

        let surveyCreatorOptions: ICreatorOptions
        if (surveyType === SurveyType.rating) {
            surveyCreatorOptions = SurveyCreatorUtils.creatorOptions.forRatingSurvey
            settings.designer.showAddQuestionButton = false
        } else {
            surveyCreatorOptions = SurveyCreatorUtils.creatorOptions.default
            settings.designer.showAddQuestionButton = true
            settings.designer.defaultAddQuestionType = 'rating'
        }

        const creatorInstance = new SurveyCreator(surveyCreatorOptions)

        if (surveyType === SurveyType.fullBlown) {
            SurveyCreatorUtils.autoSelectFullBlownSurveyDefaultValues(creatorInstance)
        }

        if (surveyType === SurveyType.onboarding) {
            SurveyCreatorUtils.autoSelectOnboardingSurveyDefaultValues(creatorInstance)
        }

        if (surveyType === SurveyType.rating) {
            SurveyCreatorUtils.removeDuplicateButton(creatorInstance)
        }

        creatorInstance.onActiveTabChanged.add(() => {
            const svcJsonImport = document.getElementById('svc-json-import')
            if (svcJsonImport) {
                svcJsonImport.style.display = 'none'
            }
        })

        // Restricting user to only add 1 question per page
        //
        // Using `questionPerPage` mode breaks the Translation functionality and also having an impact on the produced JSON structure
        // This is the alternative way to only have only 1 question per page added

        creatorInstance.onQuestionAdded.add(function (
            _sender: any,
            options: { page: { questions: string | any[]; removeQuestion: (arg0: any) => void }; question: any }
        ) {
            if (options?.page?.questions?.length > 1) {
                options.page.removeQuestion(options.question)
                enqueueSnackbar(t('error.1questionError'), {
                    content: customErrorSnackbar,
                    anchorOrigin: customAnchor as SnackbarOrigin,
                })
            }
        })

        const updateSurveyData = async (definitionData: any = undefined) => {
            const surveyData = {
                definition: definitionData || creatorInstance.JSON,
            }
            updateSurvey({ id: surveyId, surveyData })
        }

        // Saves values when defaultLanguage is modified
        creatorInstance.saveSurveyFunc = () => {
            updateSurveyData()
        }

        creatorInstance.onActiveTabChanged.add((sender, options) => {
            if (creatorInstance.activeTab === 'translation') {
                if (tempCreatorInstanceJSON) {
                    // Note: it can be modified only during the 'translation tab'
                    creatorInstance.JSON = tempCreatorInstanceJSON
                    tempCreatorInstanceJSON = null
                }
                // We have to wait for the html page to render after we open the translation tab, so we can use document.getElementsByName
                setTimeout(() => {
                    document.getElementsByName('locales').forEach((element: HTMLElement) => {
                        element.onchange = (value: any) => {
                            let data = JSON.parse(JSON.stringify(creatorInstance.JSON))
                            const language = value.target.value
                            const isRemoved = !!value.target.checked === false

                            if (isRemoved) {
                                sanitizeTranslationFor(data, language)
                                updateSurveyData(data)
                                tempCreatorInstanceJSON = data
                            } else {
                                updateSurveyData()
                                tempCreatorInstanceJSON = null
                            }
                        }
                    })
                }, 100)
            }
        })

        // make creator auto-save
        creatorInstance.isAutoSave = true
        creatorInstance.autoSaveDelay = 0
        creatorInstance.showSaveButton = false

        if (data?.definition) creatorInstance.JSON = data.definition

        return creatorInstance
    }, [data?.definition, enqueueSnackbar, t, updateSurvey, surveyId, data?.surveyTypeConfig.surveyType])

    return (
        <LoadingContainer isLoading={isLoading || isFetching} error={error}>
            <SurveyJsCreator creator={creator} />
        </LoadingContainer>
    )
}
