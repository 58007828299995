import 'style/index.css'

import { PreferencesProvider } from '@control-tower/aerq-navigation-library/dist/auth/state/Preferences/context'
import { Loadingindicator } from '@control-tower/aerq-ui-library'
import { CssBaseline } from '@mui/material'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { AuthConfigProvider } from 'components/auth/AuthConfigProvider'
import { ErrorBoundary } from 'components/errors/ErrorBoundary'
import { SnackbarProvider } from 'notistack'
import { Suspense, useEffect } from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { store } from 'services/redux/store'
import { appTheme } from 'style/theme'
import { startMultiLanguageSupport } from 'utils/i18n'

const hideNeutralLoadingOverlay = () => {
    // @ts-ignore
    document.getElementById('neutral-loading-mask').style.display = 'none'
}

export const App = () => {
    startMultiLanguageSupport()

    useEffect(() => {
        hideNeutralLoadingOverlay()
    }, [])

    return (
        <Suspense fallback={<Loadingindicator />}>
            <SnackbarProvider>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={appTheme}>
                        <CssBaseline />
                        <PreferencesProvider>
                            <ErrorBoundary>
                                <ReduxProvider store={store}>
                                    <AuthConfigProvider />
                                </ReduxProvider>
                            </ErrorBoundary>
                        </PreferencesProvider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </SnackbarProvider>
        </Suspense>
    )
}
