import { DateField } from '@control-tower/aerq-ui-library'
import { useTranslation } from 'react-i18next'
import { TSurveyStatus } from 'types'
import { getDefaultDateFormatOptions } from 'utils/getDefaultDateFormatOptions'

/**
 * Get column config for Survey Listing Table based on status
 * @param status
 * @returns
 */
export const useColumnConfig = (status: TSurveyStatus) => {
    const { t } = useTranslation()
    const isActiveOrArchived = status === 'active' || status === 'archived'
    const baseConfig = {
        technicalName: {
            label: t('title.internalTitle'),
            tooltip: false,
            minWidth: 1,
        },
        surveyType: {
            label: t('basic.surveyType'),
            tooltip: false,
            minWidth: 1,
            cellDataGetter: (props: any) => t(`surveyType.${props.rowData.surveyTypeConfig?.surveyType || 'nomatch'}`),
        },
    }

    return {
        ...baseConfig,
        ...(isActiveOrArchived
            ? {
                  responseCount: {
                      label: t('basic.responses'),
                      tooltip: false,
                      minWidth: 1,
                  },
              }
            : {
                  // no idea at the moment how archived column config will look like so using the same as drafts
                  updatedAt: {
                      label: t('basic.lastEdited'),
                      tooltip: false,
                      minWidth: 1,
                      // eslint-disable-next-line react/display-name
                      cellRenderer: (props: any) => (
                          <DateField
                              date={props.cellData}
                              type="short"
                              dateTodayLabel="Today"
                              preferences={getDefaultDateFormatOptions()}
                          />
                      ),
                  },
              }),
    }
}
