import { Label } from '@control-tower/aerq-ui-library'
import { RefreshIcon } from '@control-tower/aerq-ui-library/dist/assets/svg'
import { useTranslation } from 'react-i18next'

import styles from './RuleManifestConflictResolutionModal.module.css'

export interface IConflictActions {
    onRefresh: () => Promise<void>
    onResolveAll: () => Promise<void>
}
export const ConflictActions = ({ onRefresh, onResolveAll }: IConflictActions) => {
    const { t } = useTranslation()

    return (
        <div className={styles.conflictActionsContainer}>
            <Label type="grey" color="500" variant="small" className={styles.clickableLabel} onClick={onRefresh}>
                {t('conflictResolutionModal.refreshList')} <RefreshIcon width={16} height={16} style={{ marginBottom: '-1px' }} />
            </Label>
            <Label type="gradient" color="main" variant="small" className={styles.clickableLabel} onClick={onResolveAll}>
                {t('conflictResolutionModal.resolveAll')}
            </Label>
        </div>
    )
}
