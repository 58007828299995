import { Label } from '@control-tower/aerq-ui-library'
import { useTranslation } from 'react-i18next'

import styles from './NoFlightRule.module.css'

interface Props {
    setIsEditing: (isEditing: boolean) => void
    readonly: boolean
    isFullBlownSurvey: boolean
}

export const NoFlightRules = ({ setIsEditing, readonly, isFullBlownSurvey }: Props) => {
    const { t } = useTranslation()

    return (
        <div className={styles.noRuleContainer}>
            <div className={styles.wrapCenterNoRule}>
                <div className={styles.wrapText}>
                    <Label
                        color="800"
                        type="grey"
                        title={isFullBlownSurvey ? t('rules.flightNumberRequired') : t('rules.onAllFLights')}
                        variant="small"
                    />
                </div>
                {!readonly && (
                    <div className={styles.wrapButton}>
                        <Label className={styles.editRule} title={t('action.edit')} fontSize="XSmall" onClick={() => setIsEditing(true)} />
                    </div>
                )}
            </div>
        </div>
    )
}
