import { Label } from '@control-tower/aerq-ui-library'

import styles from '../SurveyListing.module.css'

export interface NoResultProps {
    searchTerm: string
}

export const NoResult = ({ searchTerm }: NoResultProps) => {
    return (
        <div className={styles.noSurveyContainer}>
            <Label variant="body" title="Your search" />
            <Label variant="body" fontWeight="Semibold" title={` "${searchTerm}" `} />
            <Label variant="body" title="did not yield any results." />
        </div>
    )
}
