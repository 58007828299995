import { getToken } from '@control-tower/aerq-navigation-library'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { KeycloakService } from 'services/KeycloakService'
import { IChangeSurveyBackground } from 'types/interfaces/IChangeSurveyBackground'
import { ICopySurvey } from 'types/interfaces/ICopySurvey'
import { ICreateSurvey } from 'types/interfaces/ICreateSurvey'
import { IGetSurveyResponseDto } from 'types/interfaces/IGetSurveyResponseDto'
import { IListQueryConfig } from 'types/interfaces/IListQueryConfig'
import { ISurveyDto } from 'types/interfaces/ISurveyDto'
import { ISurveyJsConfig } from 'types/interfaces/ISurveyjsConfig'
import { ISurveyListResponse } from 'types/interfaces/ISurveyListResponse'
import { ISurveyTypeItem } from 'types/interfaces/ISurveyTypeItem'
import { IUpdateSurvey } from 'types/interfaces/IUpdateSurvey'

export const surveyApi = createApi({
    reducerPath: 'survey',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/v1',
        prepareHeaders: async (headers) => {
            try {
                const keycloakService = KeycloakService.getInstance()
                const keycloakInstance = keycloakService.getKeycloakInstance()

                if (keycloakInstance) {
                    const authHeader = await getToken(keycloakInstance)

                    headers.set('Authorization', authHeader?.Authorization)
                    headers.set('CT-ProfileId', keycloakService.getCtProfileId())
                }

                return headers
            } catch (e) {
                console.error('Error getting token from keycloakInstance', e)

                return headers
            }
        },
    }),
    tagTypes: ['Survey', 'ListSurveys', 'SurveyTypes', 'SurveyTimeTriggerOptions', 'SurveyjsConfig'],
    endpoints: (builder) => {
        return {
            getSurveyListing: builder.query<ISurveyListResponse, IListQueryConfig>({
                query: ({ status, surveyType, offset, limit, technicalName, orderConfig }) => ({
                    url: '/survey',
                    params: {
                        status,
                        offset,
                        limit,
                        surveyType,
                        technicalName: technicalName?.length ? technicalName : undefined,
                        ...orderConfig,
                    },
                }),
                providesTags: (result) =>
                    result?.payload
                        ? [...result.payload.map(({ _id }) => ({ type: 'Survey' as const, id: `${_id}` })), 'ListSurveys' as const]
                        : ['ListSurveys'],
            }),
            getSurvey: builder.query<IGetSurveyResponseDto, string>({
                query: (surveyId) => `/survey/${surveyId}`,
                providesTags: (result) => (result ? [{ type: 'Survey' as const, id: result._id }] : []),
            }),
            getSurveyTypes: builder.query<ISurveyTypeItem[], void>({
                query: () => `/survey/types`,
                providesTags: ['SurveyTypes'],
            }),

            getResponses: builder.query<ISurveyDto, string>({
                query: (surveyId) => `/response/${surveyId}`,
                providesTags: ['ListSurveys'],
            }),
            createSurvey: builder.mutation<ISurveyDto, ICreateSurvey>({
                query: (surveyData) => ({
                    url: '/survey',
                    method: 'POST',
                    body: surveyData,
                }),
                invalidatesTags: (_result, error) => (error ? [] : ['ListSurveys']),
            }),
            updateSurvey: builder.mutation<ISurveyDto, { id: string; surveyData: IUpdateSurvey }>({
                query: ({ id, surveyData }) => ({
                    url: `/survey/${id}`,
                    method: 'PUT',
                    body: surveyData,
                }),
                invalidatesTags: (_result, error, arg) => (error ? [] : [{ type: 'Survey' as const, id: `${arg.id}` }]),
            }),
            updateSurveyDefinition: builder.mutation<ISurveyDto, { id: string; surveyData: IUpdateSurvey }>({
                // We use this from SurveyCreator to make auto-save happen. There no cache invalidation needed.
                // However using exactly the same endpoint as updateSurvey
                query: ({ id, surveyData }) => ({
                    url: `/survey/${id}`,
                    method: 'PUT',
                    body: surveyData,
                }),
                invalidatesTags: (_result, error) => (error ? [] : ['ListSurveys']),
            }),
            activateSurvey: builder.mutation<ISurveyDto, string>({
                query: (id) => ({
                    url: `/survey/${id}/activate`,
                    method: 'PUT',
                }),
                invalidatesTags: (_result, error) => (error ? [] : ['Survey', 'ListSurveys']),
            }),
            archiveSurvey: builder.mutation<ISurveyDto, string>({
                query: (id) => ({
                    url: `/survey/${id}/archive`,
                    method: 'PUT',
                }),
                invalidatesTags: (_result, error) => (error ? [] : ['Survey', 'ListSurveys']),
            }),
            archiveSurveyWithoutInvalidate: builder.mutation<ISurveyDto, string>({
                query: (id) => ({
                    url: `/survey/${id}/archive`,
                    method: 'PUT',
                }),
            }),
            changeSurveyBackground: builder.mutation<ISurveyDto, { id: string; surveyData: IChangeSurveyBackground | null }>({
                query: ({ id, surveyData }) => {
                    const formData = new FormData()
                    if (surveyData) {
                        formData.append('backgroundImage', surveyData.backgroundImage)
                    }
                    return {
                        url: `/survey/${id}/background`,
                        method: 'PUT',
                        body: formData,
                    }
                },
            }),
            copySurvey: builder.mutation<ISurveyDto, { id: string; surveyData: ICopySurvey }>({
                query: ({ id, surveyData }) => ({
                    url: `/survey/${id}/copy`,
                    method: 'POST',
                    body: surveyData,
                }),
                invalidatesTags: (_result, error) => (error ? [] : ['ListSurveys']),
            }),
            deleteSurvey: builder.mutation<ISurveyDto, { id: string }>({
                query: ({ id }) => ({
                    url: `/survey/${id}`,
                    method: 'DELETE',
                }),
                invalidatesTags: (_result, error) => (error ? [] : ['ListSurveys']),
            }),
            getSurveyJsConfig: builder.query<ISurveyJsConfig, void>({
                query: () => ({
                    url: '/surveyjs/config',
                    method: 'POST',
                }),
                providesTags: ['SurveyjsConfig'],
            }),
        }
    },
})

export const {
    useGetSurveyListingQuery,
    useGetSurveyQuery,
    useGetSurveyTypesQuery,
    useGetResponsesQuery,
    useCreateSurveyMutation,
    useUpdateSurveyMutation,
    useActivateSurveyMutation,
    useArchiveSurveyMutation,
    useArchiveSurveyWithoutInvalidateMutation,
    useChangeSurveyBackgroundMutation,
    useCopySurveyMutation,
    useDeleteSurveyMutation,
    useUpdateSurveyDefinitionMutation,
    useGetSurveyJsConfigQuery,
} = surveyApi
