import { OrderByKeys } from 'utils/mapOrderByKeys'

type ReturnType = {
    value: OrderByKeys
    label: string
}[]

/**
 * Returns order configuration for Survey Listing
 */
export const useOrderConfig = (): ReturnType => {
    return [
        { value: 'last_changed', label: 'Last changed' },
        { value: 'alphabetical', label: 'Alphabetical' },
        { value: 'newest_first', label: 'Newest First' },
        { value: 'oldest_first', label: 'Oldest First' },
        { value: 'survey_type', label: 'By Survey Type' },
    ]
}
