import { ContentContainerTemplate, CustomButton, IconButton, Label } from '@control-tower/aerq-ui-library'
import BackButtonIcon from '@control-tower/aerq-ui-library/dist/assets/svg/BackButtonIcon'
import { ReactComponent as ExternalLinkIcon } from '@control-tower/aerq-ui-library/dist/assets/svg/external-link.svg'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useGetAnalyticsConfigQuery } from 'services/configApi'

import { SurveyAnalytics } from './SurveyAnalytics'
import styles from './SurveyAnalytics.module.css'

export const Page = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const { isError, isLoading, data } = useGetAnalyticsConfigQuery()

    return (
        <ContentContainerTemplate className="contentContainer">
            <IconButton
                icon={<BackButtonIcon />}
                label={t('action.goBack')}
                onClick={() => (window.history.length > 1 ? navigate(-1) : navigate('/home'))}
            />

            <div className={styles.headerWrapper}>
                <Label type="gradient" color="main" title={t('title.analytics')} />

                <CustomButton
                    endIcon={<ExternalLinkIcon />}
                    disabled={isLoading || isError || !data?.dataPortalUrl}
                    variant="contained"
                    color="primary"
                    href={data?.dataPortalUrl}
                    // @ts-ignore
                    target="_blank"
                >
                    {t('title.detailedInsights')}
                </CustomButton>
            </div>
            <SurveyAnalytics />
        </ContentContainerTemplate>
    )
}
