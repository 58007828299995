import { ContextMenu, ContextMenuItem, CustomButton } from '@control-tower/aerq-ui-library'
import { ReactComponent as MoreOptionsIcon } from '@control-tower/aerq-ui-library/dist/assets/svg/moreoptionsicon.svg'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SurveyType } from 'types/enums/SurveyType'

import { DisabledArchiveMenuItem } from './components/DisabledArchiveMenuItem'
import styles from './FlyOutMenu.module.css'

interface Props {
    surveyType: SurveyType
    showArchiveButton: boolean
    showDeleteButton: boolean
    handleDuplicateSurveyClick: () => void
    handleDeleteSurveyClick: () => void
    handleArchiveSurveyClick: () => void
}

export const FlyOutMenu = ({
    surveyType,
    showArchiveButton,
    showDeleteButton,
    handleDuplicateSurveyClick,
    handleDeleteSurveyClick,
    handleArchiveSurveyClick,
}: Props) => {
    const { t } = useTranslation()

    const [contextAnchorEl, setContextAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null)

    const isFullBlownSurvey = surveyType === SurveyType.fullBlown

    const handleContextMenuTriggerClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setContextAnchorEl(event.currentTarget)
    }

    const handleContextMenuClose = () => {
        setContextAnchorEl(null)
    }

    return (
        <>
            <CustomButton
                variant="outlined"
                color="secondary"
                //@ts-ignore
                iconOnly
                onClick={handleContextMenuTriggerClick}
            >
                <MoreOptionsIcon className={styles.moreOptionIcon} />
            </CustomButton>
            <ContextMenu anchorEl={contextAnchorEl} open={Boolean(contextAnchorEl)} onClose={handleContextMenuClose}>
                <ContextMenuItem
                    onClick={() => {
                        handleContextMenuClose()
                        handleDuplicateSurveyClick() // trigger user action
                    }}
                >
                    {t('action.duplicateSurvey')}
                </ContextMenuItem>
                {showArchiveButton &&
                    (isFullBlownSurvey ? (
                        <ContextMenuItem
                            onClick={() => {
                                handleContextMenuClose()
                                handleArchiveSurveyClick() // trigger user action
                            }}
                            children={t('action.archiveSurvey')}
                        />
                    ) : (
                        <DisabledArchiveMenuItem />
                    ))}
                {showDeleteButton && (
                    <ContextMenuItem
                        className={styles.redLabel}
                        onClick={() => {
                            handleContextMenuClose()
                            handleDeleteSurveyClick() // trigger user action
                        }}
                    >
                        {t('action.deleteSurvey')}
                    </ContextMenuItem>
                )}
            </ContextMenu>
        </>
    )
}
