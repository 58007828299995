import { FileUploader, Label, ManagedFile, TranslationDictionary, UploadedFile } from '@control-tower/aerq-ui-library'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useChangeSurveyBackgroundMutation } from 'services/surveyApi'
import { TSurveyTheme } from 'types'
import { ISurveyBackgroundImageDto } from 'types/interfaces/ISurveyBackgroundImageDto'

export interface IBackgroundImageUploaderProps {
    surveyId: string
    backgroundImage?: ISurveyBackgroundImageDto | null
    readOnly: boolean
}

export const BackgroundImageUploader = ({ surveyId, backgroundImage, readOnly }: IBackgroundImageUploaderProps) => {
    const { t } = useTranslation()

    const [changeBackground] = useChangeSurveyBackgroundMutation()
    const [managedFiles, setManagedFiles] = useState(null as ManagedFile[] | null)
    const [theme, setTheme] = useState(backgroundImage?.theme as null | TSurveyTheme)

    useEffect(() => {
        if (backgroundImage) {
            setManagedFiles([
                new ManagedFile({
                    file: null,
                    uploaded: true,
                    isLoading: false,
                    uploadedFile: new UploadedFile({
                        name: backgroundImage.name,
                        size: backgroundImage.size,
                        url: backgroundImage.url,
                        type: backgroundImage.mimeType,
                    }),
                }),
            ])
            setTheme(backgroundImage?.theme)
        } else {
            setManagedFiles(null)
            setTheme(null)
        }
    }, [backgroundImage])

    return (
        <div>
            {backgroundImage || !readOnly ? (
                <Label color="600" type="grey" title={t('fileUpload.surveyImage')} variant="small" fontSize="XSmall" fontWeight="Normal" />
            ) : null}
            <FileUploader
                disabled={readOnly}
                t={
                    {
                        chooseFile: t('fileUpload.chooseFile'),
                        dropFile: t('fileUpload.dropFile'),
                        instruction: t('fileUpload.instruction'),
                        or: t('fileUpload.or'),
                        surveyImage: t('fileUpload.surveyImage'),
                        uploadedFiles: t('fileUpload.uploadedFiles'),
                        uploadedImages: t('fileUpload.uploadedImages'),
                    } as TranslationDictionary
                }
                multiple={false}
                singleColumn={false}
                uploadOnce={true}
                accept={'image/*'}
                onFileUpload={async (files: ManagedFile[]) => {
                    const managedFile = files[0]
                    if (!managedFile.file) {
                        return
                    }
                    try {
                        managedFile.isLoading = true
                        setManagedFiles([managedFile])
                        const survey = await changeBackground({
                            id: surveyId,
                            surveyData: { backgroundImage: managedFile.file },
                        }).unwrap()

                        if (survey.backgroundImage) {
                            const image = survey.backgroundImage as ISurveyBackgroundImageDto
                            const managedFile = new ManagedFile({
                                file: null,
                                uploaded: true,
                                isLoading: false,
                                uploadedFile: new UploadedFile({
                                    name: image.name,
                                    size: image.size,
                                    url: image.url,
                                    type: image.mimeType,
                                }),
                            })
                            setManagedFiles([managedFile])
                            setTheme(survey.backgroundImage.theme)
                        }
                    } catch (e: any) {
                        if (managedFile) {
                            let errorMessage = t('fileUpload.error.upload')
                            switch (e.originalStatus) {
                                case 415:
                                    errorMessage = t('fileUpload.error.mediaType')
                                    break
                                case 413:
                                    errorMessage = t('fileUpload.error.payloadTooLarge')
                                    break
                                default:
                                    break
                            }
                            managedFile.errorMessage = errorMessage
                            managedFile.uploaded = false
                            managedFile.isLoading = false
                            managedFile.uploadProgress = 0
                        }
                        setManagedFiles(managedFile ? [managedFile] : null)
                    }
                }}
                onFileDelete={async (file: ManagedFile) => {
                    try {
                        if (!file.errorMessage) {
                            await changeBackground({
                                id: surveyId,
                                surveyData: null,
                            }).unwrap()
                        }
                        setManagedFiles(null)
                        setTheme(null)
                    } catch (error) {
                        // eslint-disable-next-line no-console
                        console.warn('ERROR during file delete. Please refresh your screen and try again.', error)
                    }
                }}
                managedFiles={managedFiles}
            />
            {theme && (
                <div style={{ width: '443px' }}>
                    <Label
                        color="600"
                        type="grey"
                        title={`${t(theme === 'LIGHT' ? 'fileUpload.theme.light' : 'fileUpload.theme.dark')}`}
                        variant="small"
                        lineHeight="1em"
                    />
                </div>
            )}
        </div>
    )
}

export default BackgroundImageUploader
