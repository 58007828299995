import { useGetFlightRuleManifestQuery, useUpsertFlightRuleManifestMutation } from 'services/ruleManifestApi'
import { SurveyTimeTriggers } from 'types/enums/SurveyTimeTriggers'

export const useUpdateFlightRule = (surveyId: string) => {
    const { data: ruleData } = useGetFlightRuleManifestQuery({ surveyId })
    const [updateFlightRule] = useUpsertFlightRuleManifestMutation()

    const updateFlightRuleData = async (flightNumbers?: string[], timeTrigger?: SurveyTimeTriggers) => {
        const updatedRule = {
            surveyId,
            flightNumbers: flightNumbers ?? ruleData?.flightNumbers ?? [],
            timeTrigger: timeTrigger ?? ruleData?.timeTrigger ?? ('30' as SurveyTimeTriggers),
        }

        return await updateFlightRule(updatedRule).unwrap()
    }

    return { updateFlightRuleData }
}
