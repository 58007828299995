/* eslint-disable import/no-extraneous-dependencies */
import { LoadingContainer } from 'components/loadingContainer'
import { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useGetResponsesQuery } from 'services/surveyApi'
import { VisualizationPanel } from 'survey-analytics'
import { Model } from 'survey-core'
import { ISurveyResponseModel } from 'types/interfaces/ISurveyResponseModel'
import { analyticsOptions } from 'utils'

export const SurveyAnalytics = () => {
    const container = useRef<HTMLDivElement | null>(null)
    const { id }: { id?: string } = useParams()
    const surveyId = id || ''
    const { data: surveyData, error, isLoading, isFetching } = useGetResponsesQuery(surveyId)

    useEffect(() => {
        if (surveyData && container.current) {
            const formattedQuestions = surveyData.responses?.map((data: ISurveyResponseModel) => data.response || {}) || []

            const survey = new Model(surveyData.definition)
            const panel = new VisualizationPanel(survey.getAllQuestions(), formattedQuestions, analyticsOptions)
            panel.render(container.current)
        }
    }, [surveyData])

    return (
        <LoadingContainer isLoading={isLoading || isFetching} error={error}>
            <div ref={container}></div>
        </LoadingContainer>
    )
}
