import { CustomInput, Label, useWizardState, useWizardStepContext } from '@control-tower/aerq-ui-library'
import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCreateSurveyMutation } from 'services/surveyApi'
import { errorCodeToMessage } from 'utils'

import styles from './AddNameStep.module.css'

export interface AddNameStepProps {
    setCreatedSurveyId: (surveyId: string) => void
}

export const AddNameStep = ({ setCreatedSurveyId }: AddNameStepProps) => {
    const { t } = useTranslation()
    const [technicalName, setTechnicalName] = useState('')
    const { wizardEventObserver, setActualStep, setStepData } = useWizardState()
    const { index, data: currentStepData } = useWizardStepContext()
    const [createSurvey, { isLoading, isError, error }] = useCreateSurveyMutation()

    useEffect(() => {
        const nextStepHandler = async (_event: any, { actualStep }: any) => {
            if (actualStep !== index) return
            if (isLoading) return

            try {
                const result = await createSurvey({ technicalName, surveyType: currentStepData?.surveyType }).unwrap()
                setStepData({
                    stepId: 2,
                    data: { technicalName, surveyType: currentStepData?.surveyType },
                })
                setCreatedSurveyId(result._id)
                setActualStep(2)
            } catch (e) {}
        }

        // @ts-ignore
        wizardEventObserver.on('next', nextStepHandler)

        return () => {
            // @ts-ignore
            wizardEventObserver.remove('next', nextStepHandler)
        }
    }, [
        wizardEventObserver,
        setActualStep,
        setStepData,
        index,
        technicalName,
        currentStepData,
        isLoading,
        createSurvey,
        setCreatedSurveyId,
    ])

    return (
        <div className={styles.contentContainer}>
            <div className={styles.titleContainer}>
                <Label color="900" type="grey" title={t('action.nameSurvey')} variant="h5" />
            </div>

            <div>
                <CustomInput
                    className={styles.input}
                    label={t('title.internalTitle')}
                    helperText={t('title.provideTitle')}
                    error={isError}
                    // @ts-ignore
                    errorText={t(errorCodeToMessage(error?.data?.errorCode))}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setTechnicalName(e.target.value)}
                />
            </div>
        </div>
    )
}
