import { Alert } from '@control-tower/aerq-ui-library'
import clsx from 'clsx'
import { Spinner } from 'components/spinner'
import { ReactNode } from 'react'

import styles from './ChooseTypeStep.module.css'

export interface LoadingContainerProps {
    isLoading: boolean
    isError: boolean
    error?: string
    children: ReactNode
}

export const LoadingContainer = ({ isLoading, isError, error, children }: LoadingContainerProps) => {
    let content: ReactNode

    if (isLoading) {
        content = (
            <div className={clsx(styles.spinnerContainer, 'aerq-spinner')}>
                <Spinner />
            </div>
        )
    } else if (isError) {
        content = <Alert text={error || 'Something went wrong'} />
    } else {
        content = children
    }

    return <>{content}</>
}
