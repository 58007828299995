import { Header } from '@control-tower/aerq-navigation-library'
import { usePreferencesState } from '@control-tower/aerq-navigation-library/dist/auth/state/Preferences/context'
import { PropsWithChildren, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, To, useLocation, useNavigate } from 'react-router-dom'

export const Layout = ({ children }: PropsWithChildren<{}>) => {
    const navigate = useNavigate()
    const location = useLocation()
    const { i18n } = useTranslation()
    const { preferences } = usePreferencesState()
    const { language } = preferences

    const navigateTo = useCallback(
        (type: string, path: To) => {
            if (type === 'push') {
                navigate(path)
            } else {
                navigate(path, { replace: true })
            }
        },
        [navigate]
    )

    useEffect(() => {
        if (i18n.language !== language.toLowerCase) {
            i18n.changeLanguage(language.toLowerCase())
        }
    }, [i18n, language])

    return (
        <>
            {/** @ts-ignore */}
            <Header Link={Link} navigateTo={navigateTo} location={location} />
            {children}
        </>
    )
}
