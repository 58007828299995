import { CustomButton, CustomInput, Label } from '@control-tower/aerq-ui-library'
import { ChangeEvent, useState } from 'react'

import styles from './InputModalContent.module.css'

export interface InputModalContentProps {
    initialInput: string
    isUpdating: boolean
    handleSubmit: (inputValue: string) => void
    modalTitle: string
    inputLabel: string
    inputHelperText?: string
    actionButtonLabel: string
    isError: boolean
    errorMessage?: string
}

export const InputModalContent = ({
    modalTitle,
    inputLabel,
    inputHelperText,
    initialInput,
    isError,
    isUpdating,
    handleSubmit,
    actionButtonLabel,
    errorMessage,
}: InputModalContentProps) => {
    const [inputValue, setInputValue] = useState(initialInput || '')

    return (
        <>
            <div className="modalTitleContainer">
                <Label variant="h3">{modalTitle}</Label>
            </div>
            <div>
                <div className="modalInputContainer">
                    <CustomInput
                        // @ts-ignore
                        className={styles.input}
                        label={inputLabel}
                        value={inputValue}
                        fullWidth={true}
                        error={isError}
                        helperText={inputHelperText}
                        // @ts-ignore
                        errorText={errorMessage}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value)}
                    />
                </div>

                <div className="modalButtonContainer">
                    <CustomButton disabled={isUpdating} variant="contained" color="primary" onClick={() => handleSubmit(inputValue)}>
                        {actionButtonLabel}
                    </CustomButton>
                </div>
            </div>
        </>
    )
}
