import { AERQNavigation, ProfileChangedFlyout } from '@control-tower/aerq-navigation-library'
import { AuthenticationErrorPage } from 'components/errors/AuthenticationErrorPage'
import type { KeycloakInstance } from 'keycloak-js'
import { useCallback } from 'react'
import { To, useNavigate } from 'react-router-dom'
import { AppRoutes } from 'routes/AppRoutes'
import { KeycloakService } from 'services/KeycloakService'

export interface IAuthNavProps {
    authServerUrl: string
    realm: string
    clientId: string
    userManagementWebApiUrl: string
}

export const AuthNav = ({ authServerUrl, realm, clientId, userManagementWebApiUrl }: IAuthNavProps) => {
    const navigate = useNavigate()

    const navigateTo = useCallback(
        (type: string, path: To) => {
            if (type === 'push') {
                navigate(path)
            } else {
                navigate(path, { replace: true })
            }
        },
        [navigate]
    )

    return (
        <AERQNavigation
            navigateTo={navigateTo}
            keycloakOptions={{
                url: authServerUrl,
                realm: realm,
                clientId: clientId,
            }}
            onAfterSelectProfile={(orgInfo: { profileOrgId: string; userId: string }, keycloak: KeycloakInstance) => {
                KeycloakService.getInstance().init(keycloak, orgInfo.profileOrgId)
            }}
            userManagementWebApiURL={userManagementWebApiUrl}
            notificationServiceURL={undefined}
            ErrorComponent={AuthenticationErrorPage}
            onError={(e) => {
                // eslint-disable-next-line no-console
                console.error('AERQNavigation error: ', e)
            }}
        >
            <>
                <ProfileChangedFlyout />
                <AppRoutes clientId={clientId} userManagementWebApiUrl={userManagementWebApiUrl} />
            </>
        </AERQNavigation>
    )
}
