import { useTranslation } from 'react-i18next'
import { ISurveyListMeta } from "types/interfaces/ISurveyListMeta"

/**
 * Get menu configuration for displaying tabs on survey listing page
 * @param surveyCount
 * @param status
 * @returns
 */
export function useMenuConfig(surveyCount: ISurveyListMeta['surveyCount'], status: string) {
    const { t } = useTranslation()

    const formatTabCount = (count?: number) => (count ? `(${count})` : '')

    return [
        {
            url: `/home/active`,
            name: ` ${t('basic.activeSurveys')} ${formatTabCount(surveyCount?.active)}`,
        },
        {
            url: `/home/draft`,
            name: ` ${t('basic.drafts')} ${formatTabCount(surveyCount?.draft)}`,
        },
        {
            url: `/home/archived`,
            name: ` ${t('basic.archivedSurveys')} ${formatTabCount(surveyCount?.archived)}`,
        },
    ].map((menuConfigItem) => {
        return { ...menuConfigItem, active: menuConfigItem.url.includes(status) }
    })
}
