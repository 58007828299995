import { UploadIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { SnackbarOrigin, useSnackbar } from 'notistack'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { customAnchor, customErrorSnackbar } from 'utils/customSnackbarContent'

import { exportSurvey } from './api'
import styles from './ExportButton.module.css'
import { errorCodeToMessage } from './utils/errorMessageHelpers'

export interface SurveyExportProps {
    surveyId: string
}

export const ExportButton = ({ surveyId }: SurveyExportProps) => {
    const ref = useRef<HTMLAnchorElement | null>(null)
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const handleDownload = async () => {
        try {
            const { data, surveyFileName } = await exportSurvey(surveyId)

            if (ref.current) {
                ref.current.href = URL.createObjectURL(data)
                ref.current.setAttribute('download', `${surveyFileName}.csv`)
                ref.current.click()
            }
        } catch (e: any) {
            enqueueSnackbar(t(errorCodeToMessage(e?.message)), {
                content: customErrorSnackbar,
                anchorOrigin: customAnchor as SnackbarOrigin,
            })
        }
    }

    return (
        <>
            <button className={clsx(styles.exportButton, 'resetButton')} onClick={handleDownload}>
                <UploadIcon style={{ width: 24, opacity: 0.5 }} />
                {t('action.export')}
            </button>
            <a className={styles.link} ref={ref}></a>
        </>
    )
}
