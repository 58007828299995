import { Label } from '@control-tower/aerq-ui-library'
import { useTranslation } from 'react-i18next'
import { TSurveyStatus } from 'types'

import { useNoSurveyText } from '../hooks/useNoSurveyText'
import styles from '../SurveyListing.module.css'

export interface NoSurveyProps {
    status: TSurveyStatus
}

export const NoSurveys = ({ status }: NoSurveyProps) => {
    const { t } = useTranslation()

    const title = useNoSurveyText(status)

    return <Label className={styles.noSurveyText} title={t(title)} variant="h2" />
}
