import { CustomButton, Label } from '@control-tower/aerq-ui-library'
import { ChangeTitle } from 'features/ChangeTitle'
import { useTranslation } from 'react-i18next'
import { TSurveyStatus } from 'types'
import { SurveyType } from 'types/enums/SurveyType'

import { FlyOutMenu } from '../FlyOutMenu'
import styles from './Header.module.css'

interface HeaderProps {
    technicalName: string
    id: string
    status: TSurveyStatus
    surveyType: SurveyType
    handleShowDuplicateSurveyModal: () => void
    handleShowDeleteSurveyModal: () => void
    handleShowArchiveSurveyModal: () => void
    handleShowPublishSurveyModal: () => void
}

export const Header = ({
    technicalName,
    id,
    status,
    surveyType,
    handleShowDeleteSurveyModal,
    handleShowDuplicateSurveyModal,
    handleShowArchiveSurveyModal,
    handleShowPublishSurveyModal,
}: HeaderProps) => {
    const { t } = useTranslation()
    const isDraft = status === 'draft'
    const isArchived = status === 'archived'
    const isActive = status === 'active'

    const getStatus = (status: TSurveyStatus): string => {
        if (status === 'draft') return t('basic.draft')
        else if (status === 'active') return t('basic.active')
        else return t('basic.archived')
    }
    return (
        <>
            <div className={styles.titleWrapper}>
                <div className={styles.titleContainer}>
                    <Label type="gradient" color="main" className={styles.surveyName}>
                        {technicalName}
                    </Label>
                    {isDraft && <ChangeTitle id={id} initialTitle={technicalName} />}
                </div>
                <div className={styles.actionButtonsBesideTitle}>
                    {isDraft && (
                        <CustomButton variant="contained" color="primary" onClick={handleShowPublishSurveyModal}>
                            {t('action.publish')}
                        </CustomButton>
                    )}
                    <FlyOutMenu
                        handleDuplicateSurveyClick={handleShowDuplicateSurveyModal}
                        handleDeleteSurveyClick={handleShowDeleteSurveyModal}
                        handleArchiveSurveyClick={handleShowArchiveSurveyModal}
                        showDeleteButton={isDraft || isArchived}
                        showArchiveButton={isActive}
                        surveyType={surveyType}
                    />
                </div>
            </div>
            <Label color="600" fontWeight="Normal" title="Lorem ipsum" type="grey" variant="small" className={styles.status}>
                {`${t('surveyType.' + surveyType)} | ${getStatus(status)}`}
            </Label>
        </>
    )
}
