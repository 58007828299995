import { ContextMenuItem, CustomTooltip } from '@control-tower/aerq-ui-library'
import AlertIcon from '@control-tower/aerq-ui-library/dist/assets/svg/AlertIcon'
import { useTranslation } from 'react-i18next'

import styles from './DisabledArchiveMenuItem.module.css'

export const DisabledArchiveMenuItem = () => {
    const { t } = useTranslation()

    return (
        <CustomTooltip title={t('info.disabledArchive')}>
            <span>
                <ContextMenuItem disabled>
                    {
                        <div className={styles.menuItemContainer}>
                            <span> {t('action.archiveSurvey')}</span>
                            <div className={styles.iconContainer}>
                                <AlertIcon />
                            </div>
                        </div>
                    }
                </ContextMenuItem>
            </span>
        </CustomTooltip>
    )
}
