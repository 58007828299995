import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { IAnalyticsConfig } from 'types/interfaces/IAnalyticsConfig'
import { IAuthConfig } from 'types/interfaces/IAuthConfig'

export const configApi = createApi({
    reducerPath: 'config',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/v1',
    }),
    tagTypes: ['authConfig', 'analyticsConfig'],
    endpoints: (builder) => {
        return {
            getAuthConfig: builder.query<IAuthConfig, void>({
                query: () => `/config/auth`,
                providesTags: ['authConfig'],
            }),
            getAnalyticsConfig: builder.query<IAnalyticsConfig, void>({
                query: () => `/analytics/config`,
                providesTags: ['analyticsConfig'],
            }),
        }
    },
})

export const { useGetAuthConfigQuery, useGetAnalyticsConfigQuery } = configApi
