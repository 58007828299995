import { LoadingContainer } from 'components/loadingContainer'
import { useEffect, useState } from 'react'
import { useGetSurveyJsConfigQuery } from 'services/surveyApi'
// eslint-disable-next-line import/no-extraneous-dependencies
import { slk } from 'survey-core'
import { SurveyCreator, SurveyCreatorComponent } from 'survey-creator-react'

export const SurveyJsCreator = ({ creator }: { creator: SurveyCreator }) => {
    const { data, isLoading } = useGetSurveyJsConfigQuery()
    const [isLkSet, setIsLkSet] = useState(false)

    useEffect(() => {
        if (isLoading) return

        if (data?.lk) {
            slk(data.lk)
        }

        setIsLkSet(true)
    }, [data?.lk, isLoading])

    return (
        <LoadingContainer isLoading={!isLkSet}>
            <SurveyCreatorComponent creator={creator} />
        </LoadingContainer>
    )
}
