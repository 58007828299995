import { Modal } from '@control-tower/aerq-ui-library'
import { SurveyType } from 'types/enums/SurveyType'

import { RegularSurveyModal } from './RegularSurveyModal'
import { SingleTypeSurveyModal } from './SingleTypeSurveyModal'

interface Props {
    id: string
    surveyType: SurveyType
    isOpen: boolean
    hideModal: () => void
    onError: (error: any) => void
}

export const PublishSurveyModal = ({ id, surveyType, isOpen, hideModal, onError }: Props) => {
    const isRegularType = surveyType === SurveyType.fullBlown

    return (
        <Modal
            open={isOpen}
            closeBtn={true}
            handleModalState={() => hideModal()}
            maxWidth={600}
            children={
                isRegularType ? (
                    <RegularSurveyModal id={id} hideModal={hideModal} onError={onError} />
                ) : (
                    <SingleTypeSurveyModal id={id} hideModal={hideModal} surveyType={surveyType} onError={onError} />
                )
            }
        />
    )
}
