import { OrgSelector } from 'components/auth/OrgSelector'
import { ErrorPage } from 'components/errors/ErrorPage'
import { Layout } from 'components/Layout'
import { EditSurveyPage } from 'features/EditSurvey'
import { SurveyAnalyticsPage } from 'features/SurveyAnalytics'
import { AERQSurveyCreatorPage } from 'features/SurveyCreator'
import { SurveyListingPage } from 'features/SurveyListing'
import { Navigate, Route, Routes } from 'react-router-dom'

export interface AppRoutesProps {
    clientId: string
    userManagementWebApiUrl: string
}

export const AppRoutes = ({ clientId, userManagementWebApiUrl }: AppRoutesProps) => (
    <Routes>
        <Route
            path="/home/:status?"
            element={
                <Layout>
                    <SurveyListingPage />
                </Layout>
            }
        />
        <Route
            path="/edit-survey/:id"
            element={
                <Layout>
                    <EditSurveyPage />
                </Layout>
            }
        />
        <Route
            path="/survey-creator/:id"
            element={
                <Layout>
                    <AERQSurveyCreatorPage />
                </Layout>
            }
        />
        <Route
            path="/analytics/:id"
            element={
                <Layout>
                    <SurveyAnalyticsPage />
                </Layout>
            }
        />
        <Route
            path="/organization-selector"
            element={<OrgSelector clientId={clientId} userManagementWebApiUrl={userManagementWebApiUrl} />}
        />
        <Route path="/" element={<Navigate to="/home" />} />
        <Route
            path="*"
            element={
                <Layout>
                    <ErrorPage errorCode="404" />
                </Layout>
            }
        />
    </Routes>
)
