import { Label, MultiSelect } from '@control-tower/aerq-ui-library'
import { useUpdateFlightRule } from 'hooks/useUpdateFlightRule'
import { SnackbarOrigin, useSnackbar } from 'notistack'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetAvailableFlightNumbersQuery } from 'services/ruleManifestApi'
import { customAnchor, customErrorSnackbar, customSuccessSnackbar } from 'utils/customSnackbarContent'

import { LoadingContainer } from '../components/LoadingContainer'
import styles from './EditFlightRules.module.css'

interface Props {
    surveyId: string
    setIsEditing: (isEditing: boolean) => void
    setFlightNumbers: (newFlightNumbers: string[]) => void
    currentFlightNumbers: string[]
    onError: (error: any) => void
}

export const EditFlightRules = ({ surveyId, setIsEditing, setFlightNumbers, currentFlightNumbers, onError }: Props) => {
    const { t } = useTranslation()
    const {
        data: availableFlightNumbers,
        isLoading: isLoadingFlightNumbers,
        isError: isErrorLoadingFlightNumber,
    } = useGetAvailableFlightNumbersQuery()
    const { updateFlightRuleData } = useUpdateFlightRule(surveyId)
    const [isUpdateInProgress, setUpdateInProgress] = useState(false)
    const { enqueueSnackbar } = useSnackbar()

    const flightNumberOptions = useMemo(() => {
        // merge available flight numbers with the previous list in case of the currently saved is not available anymore
        const merged = Array.from(new Set([...(availableFlightNumbers?.flightNumbers || []), ...currentFlightNumbers]))

        return merged.map((flightNumber) => ({
            label: flightNumber,
            value: flightNumber,
        }))
    }, [availableFlightNumbers?.flightNumbers, currentFlightNumbers])

    const currentFlightNumbersTransformed = useMemo(
        () =>
            currentFlightNumbers?.map((flightNumber) => ({
                label: flightNumber,
                value: flightNumber,
            })),
        [currentFlightNumbers]
    )

    const handleSubmit = async () => {
        try {
            setUpdateInProgress(true)
            const response = await updateFlightRuleData(currentFlightNumbers)
            console.log(`Response for Conflicts for flight numbers:`, { response: response?.conflicts })
            enqueueSnackbar(t('success.flightRuleSuccess'), {
                content: customSuccessSnackbar,
                anchorOrigin: customAnchor as SnackbarOrigin,
            })
            setIsEditing(false)
        } catch (error) {
            console.error('Error updating flight rule:', error)
            onError(error)
            enqueueSnackbar(t('error.flightRuleError'), {
                content: customErrorSnackbar,
                anchorOrigin: customAnchor as SnackbarOrigin,
            })
        } finally {
            setUpdateInProgress(false)
        }
    }

    return (
        <LoadingContainer isLoading={isLoadingFlightNumbers} isError={isErrorLoadingFlightNumber} error={undefined}>
            {flightNumberOptions && (
                <div className={styles.editFlightRulesContainer}>
                    <MultiSelect
                        disabled={isUpdateInProgress}
                        className={styles.multiSelectInput}
                        label={t('rules.flightNumbers')}
                        helperText={t('rules.provideFlightNumbers')}
                        options={flightNumberOptions}
                        value={currentFlightNumbersTransformed}
                        error={false}
                        errorText={undefined}
                        onChange={(_event, data) => {
                            const transformedFlightNumbers = data.map(({ value }) => value)
                            setFlightNumbers(transformedFlightNumbers)
                        }}
                    />
                    <div className={styles.saveButtonContainer}>
                        {isUpdateInProgress ? (
                            <Label className={styles.processingRule} title={t('action.processing')} fontSize="XSmall" />
                        ) : (
                            <Label className={styles.editRule} title={t('action.save')} fontSize="XSmall" onClick={handleSubmit} />
                        )}
                    </div>
                </div>
            )}
        </LoadingContainer>
    )
}
