import { Label } from '@control-tower/aerq-ui-library'
import { XIcon } from '@heroicons/react/outline'
import { useNavigate, useParams } from 'react-router-dom'

import { ExportButton } from './ExportButton'
import styles from './ExportSurvey.module.css'

export const ExportHeader = () => {
    const { id }: { id?: string } = useParams()
    const surveyId = id || ''
    const navigate = useNavigate()

    return (
        <div className={styles.container}>
            <Label title="Survey Creator" fontWeight="Normal" variant="h3" />
            <div className={styles.actions}>
                <ExportButton surveyId={surveyId} />
                <XIcon className={styles.closeIcon} style={{ width: 24 }} onClick={() => navigate(-1)} />
            </div>
        </div>
    )
}
