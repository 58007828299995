import { DateFieldProps } from '@control-tower/aerq-ui-library/dist/components/DateField'

export function getDefaultDateFormatOptions(overrides?: Partial<DateFieldProps['preferences']>): DateFieldProps['preferences'] {
    const baseConfig = {
        language: 'EN',
        dateFormatOptions: {
            formats: {
                long: 'ddd, MMM D, YYYY H:mm z',
                short: {
                    today: '{{today}}, H:mm',
                    thisYear: 'MMM D, H:mm',
                    older: 'MMM D, YYYY',
                },
                reviewId: {
                    thisYear: 'MMMM D',
                    older: 'MMM D, YYYY',
                },
            },
            timeConvention: '24h',
            timeZoneId: 'Europe/Berlin',
            showTimeZone: '',
        },
    }

    return { ...baseConfig, ...overrides }
}
