import { ModalContent } from 'components/ModalContent'
import { SnackbarOrigin, useSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useActivateSurveyMutation } from 'services/surveyApi'
import { wrapInErrorHandler } from 'utils'
import { customAnchor, customSuccessSnackbar } from 'utils/customSnackbarContent'

interface Props {
    id: string
    hideModal: () => void
    onError: (error: any) => void
}

export const RegularSurveyModal = ({ id, hideModal, onError }: Props) => {
    const [error, setError] = useState<string | undefined>(undefined)
    const [activateSurvey, { isLoading: isUpdating, isError }] = useActivateSurveyMutation()

    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const handleSubmit = async () => {
        wrapInErrorHandler(
            async () => {
                // TODO: survey can be published with 0 question if the survey editor was opened and closed.
                // does this try catch break it? Test in Azure as well with updated FE and BE pods
                try {
                    await activateSurvey(id).unwrap()
                } catch (e: any) {
                    onError(e)
                    throw e
                }
                hideModal()
                enqueueSnackbar(t('success.publishSuccess'), {
                    content: customSuccessSnackbar,
                    anchorOrigin: customAnchor as SnackbarOrigin,
                })
            },
            t,
            setError
        )
    }

    return (
        <ModalContent
            handleSubmit={handleSubmit}
            modalTitle={t('basic.modalPublish')}
            description={t('basic.descriptionPublish')}
            actionButtonLabel={t('action.publish')}
            isSubmitDisabled={isUpdating}
            errorMessage={error}
            isError={isError}
        />
    )
}
