import { Snackbar } from '@control-tower/aerq-ui-library'

//@ts-ignore
export const customSuccessSnackbar = (_key, message) => {
    //@ts-ignore
    return <Snackbar message={message} variant="success" />
}

//@ts-ignore
export const customErrorSnackbar = (_key, message) => {
    //@ts-ignore
    return <Snackbar message={message} variant="error" />
}

export const customAnchor = {
    horizontal: 'center',
    vertical: 'bottom',
}
