import { SerializedError } from '@reduxjs/toolkit/dist/createAsyncThunk'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import { ErrorPage } from 'components/errors/ErrorPage'
import { Spinner } from 'components/spinner'
import { ReactNode } from 'react'

import styles from './loadingContainer.module.css'

export interface LoadingContainerProps {
    isLoading: boolean
    children: ReactNode
    error?: FetchBaseQueryError | SerializedError
}

export const LoadingContainer = ({ isLoading, error, children }: LoadingContainerProps) => {
    let content: ReactNode

    const errorCode = error && getErrorCode(error)

    if (isLoading) {
        content = (
            <div className={styles.loading}>
                <Spinner />
            </div>
        )
    } else if (error) {
        content = <ErrorPage errorCode={errorCode || '500'} />
    } else {
        content = children
    }

    return <>{content}</>
}

const getErrorCode = (error: FetchBaseQueryError | SerializedError) => {
    if (error && 'status' in error) {
        // FetchBaseQueryError
        if (error.status === 'FETCH_ERROR') return '500'

        if (error.status === 'PARSING_ERROR') return error.originalStatus.toString()

        return error.status.toString()
    }
    // SerializedError
    return error?.code
}
