import { Label } from '@control-tower/aerq-ui-library'

import styles from './RuleManifestConflictResolutionModal.module.css'

export interface IConflictResolutionErrorLabelProps {
    errorMessage: string | JSX.Element
}

export const ConflictResolutionErrorLabel = ({ errorMessage }: IConflictResolutionErrorLabelProps) => {
    return <Label title={errorMessage} variant="small" className={styles.conflictResolutionErrorLabel} />
}
