import { Modal } from '@control-tower/aerq-ui-library'
import { ModalContent } from 'components/ModalContent'
import { SnackbarOrigin, useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDeleteSurveyMutation } from 'services/surveyApi'
import { customAnchor, customErrorSnackbar, customSuccessSnackbar } from 'utils/customSnackbarContent'

interface DuplicateSurveyModalProps {
    id: string
    isDeleteSurveyModalOpen: boolean
    hideDeleteSurveyModal: () => void
}

export const DeleteSurveyModal = ({ id, isDeleteSurveyModalOpen, hideDeleteSurveyModal }: DuplicateSurveyModalProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [deleteSurvey, { isLoading: isUpdating, isError, error }] = useDeleteSurveyMutation()
    const { enqueueSnackbar } = useSnackbar()

    const handleSubmit = async () => {
        try {
            await deleteSurvey({ id }).unwrap()
            navigate(`/home`) // TODO: not sure if this is the correct behavior, we have not defined yet
            hideDeleteSurveyModal()
            enqueueSnackbar(t('success.deleteSuccess'), {
                content: customSuccessSnackbar,
                anchorOrigin: customAnchor as SnackbarOrigin,
            })
        } catch (error) {
            enqueueSnackbar(t('error.wentWrongError'), {
                content: customErrorSnackbar,
                anchorOrigin: customAnchor as SnackbarOrigin,
            })
        }
    }

    return (
        <Modal
            open={isDeleteSurveyModalOpen}
            closeBtn={true}
            handleModalState={hideDeleteSurveyModal}
            maxWidth={600}
            children={
                <>
                    <ModalContent
                        handleSubmit={handleSubmit}
                        modalTitle={t('basic.modalDelete')}
                        description={t('basic.descriptionDelete')}
                        actionButtonLabel={t('action.deleteSurvey')}
                        isSubmitDisabled={isUpdating}
                        //@ts-ignore
                        errorMessage={error?.data?.message || t('error.wentWrongError')}
                        isError={isError}
                    />
                </>
            }
        />
    )
}
