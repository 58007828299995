import { Modal } from '@control-tower/aerq-ui-library'
import { PencilIcon } from '@heroicons/react/solid'
import { InputModalContent } from 'components/InputModalContent'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUpdateSurveyMutation } from 'services/surveyApi'
import { wrapInErrorHandler } from 'utils'

import styles from './ChangeTitle.module.css'

export interface ChangeTitleProps {
    id: string
    initialTitle: string
}

export const ChangeTitle = ({ id, initialTitle }: ChangeTitleProps) => {
    const { t } = useTranslation()
    const [modalOpen, setModalOpen] = useState(false)
    const [updateSurvey, { isLoading: isUpdating }] = useUpdateSurveyMutation()
    const [error, setError] = useState<string | undefined>(undefined)

    const handleSubmit = (technicalName: string) => {
        wrapInErrorHandler(
            async () => {
                const surveyData = {
                    technicalName,
                }
                await updateSurvey({ id, surveyData }).unwrap()
            },
            t,
            setError
        )
    }

    const showModal = () => setModalOpen(true)

    const hideModal = () => setModalOpen(false)

    return (
        <>
            <button className={styles.editButton} onClick={showModal}>
                <PencilIcon className={styles.editIcon} />
            </button>
            <Modal
                open={modalOpen}
                closeBtn={true}
                handleModalState={hideModal}
                maxWidth={600}
                children={
                    <InputModalContent
                        initialInput={initialTitle}
                        isUpdating={isUpdating}
                        modalTitle={t('title.surveyTitle')}
                        inputLabel={t('title.internalTitle')}
                        actionButtonLabel={t('action.save')}
                        inputHelperText={t('title.provideTitle')}
                        handleSubmit={handleSubmit}
                        isError={!!error}
                        errorMessage={error}
                    />
                }
            />
        </>
    )
}
