import { Label } from '@control-tower/aerq-ui-library'
import { AddSurveyWizard } from 'features/AddSurvey/AddSurveyWizard'

import styles from './ListingHeader.module.css'

export function ListingHeader({ title }: { title: string }) {
    return (
        <div className={styles.header}>
            <Label variant="h1" type="gradient" color="main">
                {title}
            </Label>
            <div className={styles.headerButtons}>
                {/* TODO: these buttons only to be shown when user's role enabling it */}
                <AddSurveyWizard />
            </div>
        </div>
    )
}
