import Label from '@control-tower/aerq-ui-library/dist/components/labels/Label'
import WideLink from '@control-tower/aerq-ui-library/dist/components/WideLink/WideLink'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import styles from './ErrorPage.module.css'

interface ErrorPageProps {
    errorCode: string
}

export const ErrorPage = ({ errorCode }: ErrorPageProps) => {
    const { t } = useTranslation()

    const errorTitle = getErrorTitle(errorCode)
    const errorMessage = getErrorMessage(errorCode)
    const navigate = useNavigate()

    return (
        <div className={styles.container}>
            <Label title={t(errorTitle)} variant="h2" type="gradient" color="main" className={styles.m1} />
            <Label
                title={`Error code: ${errorCode}`}
                fontSize="Small"
                fontWeight="Medium"
                lineHeight="18.83px"
                type="grey"
                color="600"
                className={styles.m2}
            />
            <Label title={t(errorMessage)} variant="body" type="grey" color="700" className={styles.m2} />
            <WideLink linkLabel="Go to home" onClick={() => navigate('/home')} className={styles.p0} />
        </div>
    )
}

const getErrorTitle = (errorCode: string) => {
    switch (errorCode) {
        case '400':
            return 'error.wentWrongError'
        case '404':
            return 'error.pageNotFound'
        case '500':
        default:
            return 'error.wentWrongError'
    }
}

const getErrorMessage = (errorCode: string) => {
    switch (errorCode) {
        case '400':
            return 'error.400message'
        case '404':
            return 'error.404message'
        case '500':
        default:
            return 'error.500message'
    }
}
