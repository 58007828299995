import { OrganizationSelector } from '@control-tower/aerq-navigation-library'
import { useCallback } from 'react'
import { Link, To, useLocation, useNavigate } from 'react-router-dom'
import { KeycloakService } from 'services/KeycloakService'

export interface OrgSelectorProps {
    clientId: string
    userManagementWebApiUrl: string
}

export const OrgSelector = ({ clientId, userManagementWebApiUrl }: OrgSelectorProps) => {
    const navigate = useNavigate()
    const location = useLocation()
    const navigateTo = useCallback(
        (type: string, path: To) => {
            if (type === 'push') {
                navigate(path)
            } else {
                navigate(path, { replace: true })
            }
        },
        [navigate]
    )
    return (
        <OrganizationSelector
            Link={Link}
            navigateTo={navigateTo}
            location={location}
            onAfterSelectProfile={(orgInfo: any, keycloak: any) => {
                KeycloakService.getInstance().init(keycloak, orgInfo.profileOrgId)
            }}
            userManagementWebApiURL={userManagementWebApiUrl}
            // @ts-ignore
            notificationServiceURL={undefined}
            clientId={clientId}
        />
    )
}
