import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetSurveyTimeTriggerOptionsQuery } from 'services/ruleManifestApi'
import { SurveyTimeTriggers } from 'types/enums/SurveyTimeTriggers'
import { ISurveyTimeTriggerItem } from 'types/interfaces/ISurveyTimeTriggerItem'
import { defaultLocale } from 'utils/i18n'

import { LoadingContainer } from './components/LoadingContainer'
import styles from './TimeTriggerBlock.module.css'
import { TimeTriggerSelector } from './TimeTriggerSelector'

interface TimeTriggerBlockProps {
    surveyId: string
    readonly: boolean
    currentTimeTriggerValue: SurveyTimeTriggers
}

export const TimeTriggerBlock = ({ surveyId, readonly, currentTimeTriggerValue }: TimeTriggerBlockProps) => {
    const { i18n } = useTranslation()
    const { data, isLoading, isFetching, isError } = useGetSurveyTimeTriggerOptionsQuery()

    const translatedOptions = useMemo(
        () =>
            data?.map((optionItem: ISurveyTimeTriggerItem) => ({
                id: optionItem.id.toString(),
                // @ts-ignore
                name: optionItem.translations?.[i18n.language]?.label || optionItem.translations?.[defaultLocale]?.label,
            })) ?? [],
        [data, i18n]
    )

    return (
        <div className={styles.timeTriggerSelectWrapper}>
            <LoadingContainer isLoading={isLoading || isFetching} isError={isError}>
                <TimeTriggerSelector
                    readonly={readonly}
                    currentTimeTriggerValue={currentTimeTriggerValue}
                    timeTriggerOptions={translatedOptions}
                    surveyId={surveyId}
                />
            </LoadingContainer>
        </div>
    )
}
