import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGetFlightRuleManifestQuery } from 'services/ruleManifestApi'

import { LoadingContainer } from '../components/LoadingContainer'
import { EditFlightRules } from '../EditFlightRules'
import { FlightRulesContainer } from '../FlightRulesContainer'

interface Props {
    readonly: boolean
    isFullBlownSurvey: boolean
    onError: (error: any) => void
}

export const FlightRuleBlock = ({ readonly, isFullBlownSurvey, onError }: Props) => {
    const { id }: { id?: string } = useParams()
    const surveyId = id || ''

    const [isEditing, setIsEditing] = useState(false)
    const [flightNumbers, setFlightNumbers] = useState<string[]>([])
    const { data, isLoading, isFetching, isError, error } = useGetFlightRuleManifestQuery({ surveyId })

    useEffect(() => {
        if (!isLoading && !isFetching && !isError && data) {
            setFlightNumbers(data?.flightNumbers ?? [])
        }
    }, [data, isLoading, isFetching, isError, error])

    return (
        <LoadingContainer isLoading={isLoading || isFetching} isError={isError} error={error}>
            {isEditing ? (
                <EditFlightRules
                    setIsEditing={setIsEditing}
                    setFlightNumbers={setFlightNumbers}
                    currentFlightNumbers={flightNumbers}
                    surveyId={surveyId}
                    onError={onError}
                />
            ) : (
                <FlightRulesContainer
                    readonly={readonly}
                    setIsEditing={setIsEditing}
                    isFullBlownSurvey={isFullBlownSurvey}
                    flightNumbers={flightNumbers}
                />
            )}
        </LoadingContainer>
    )
}
