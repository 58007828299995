import { Alert } from '@control-tower/aerq-ui-library'
import { SerializedError } from '@reduxjs/toolkit/dist/createAsyncThunk'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import clsx from 'clsx'
import { Spinner } from 'components/spinner'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './LoadingContainer.module.css'

export interface LoadingContainerProps {
    isLoading: boolean
    isError: boolean
    error?: FetchBaseQueryError | SerializedError
    children: ReactNode
}

export const LoadingContainer = ({ isLoading, isError, error, children }: LoadingContainerProps) => {
    const { t } = useTranslation()

    let content: ReactNode

    if (isLoading) {
        content = (
            <div className={clsx(styles.spinnerContainer, 'aerq-spinner')}>
                <Spinner />
            </div>
        )
    } else if (isError) {
        content = <Alert text={t(getErrorCode(error))} />
    } else {
        content = children
    }

    return <>{content}</>
}

const getErrorCode = (error?: FetchBaseQueryError | SerializedError) => {
    if (error && 'data' in error) {
        return 'error.couldNotGetFlightNumbers'
    }

    return 'error.wentWrongError'
}
