import { Label } from '@control-tower/aerq-ui-library/dist/components'
import { useTranslation } from 'react-i18next'

import styles from './FlightRules.module.css'

interface Props {
    flightNumbers: string[]
    setIsEditing: (isEditing: boolean) => void
    readonly: boolean
}

export const FlightRules = ({ flightNumbers, setIsEditing, readonly }: Props) => {
    const { t } = useTranslation()

    return (
        <div className={styles.ruleContainer}>
            <div className={styles.wrapRule}>
                {flightNumbers?.map((text, idx) => (
                    <div key={idx} className={styles.ruleChip}>
                        <Label color="900" type="grey" title={text} variant="small" />
                    </div>
                ))}
            </div>
            {!readonly && (
                <div className={styles.editButton}>
                    <Label className={styles.editRule} title={t('action.edit')} fontSize="XSmall" onClick={() => setIsEditing(true)} />
                </div>
            )}
        </div>
    )
}
