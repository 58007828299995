import { CustomButton, Label } from '@control-tower/aerq-ui-library'
import { useTranslation } from 'react-i18next'
import { SurveyType } from 'types/enums/SurveyType'

import styles from './SingleTypeModalContent.module.css'

export interface InputModalContentProps {
    surveyType: SurveyType
    isSubmitDisabled: boolean
    isError: boolean
    errorMessage?: string
    handleSubmit: () => void
    hideModal: () => void
}

export const SingleTypeModalContent = ({
    surveyType,
    isError,
    isSubmitDisabled,
    errorMessage,
    handleSubmit,
    hideModal,
}: InputModalContentProps) => {
    const { t } = useTranslation()

    return (
        <>
            <div className="modalTitleContainer">
                <Label variant="h3">{t('basic.modalPublish')}</Label>
            </div>
            <div>
                <div className="modalInputContainer">
                    <div className={styles.descriptionContainer}>
                        <Label
                            color="800"
                            type="grey"
                            title={t(
                                surveyType === SurveyType.onboarding ? 'warning.activateOnboardingSurvey' : 'warning.activateRatingSurvey'
                            )}
                            variant="body"
                        />
                    </div>
                    <Label color="800" type="grey" title={t('action.proceed')} variant="body" />
                    {isError && <div style={{ color: 'red', marginTop: 20 }}>{errorMessage}</div>}
                </div>

                <div className={styles.modalButtonContainer}>
                    <CustomButton disabled={isSubmitDisabled} variant="outlined" color="secondary" onClick={() => hideModal()}>
                        {t('action.cancel')}
                    </CustomButton>
                    <CustomButton disabled={isSubmitDisabled} variant="contained" color="primary" onClick={() => handleSubmit()}>
                        {t('action.letsdoit')}
                    </CustomButton>
                </div>
            </div>
        </>
    )
}
