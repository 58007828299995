import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { OrderByKeys } from 'utils/mapOrderByKeys'

import { RootState } from './store'

// Define a type for the slice state
interface SortKeyState {
    value: OrderByKeys
}

// Define the initial state using that type
const initialState: SortKeyState = {
    value: 'last_changed',
}

export const sortKeySlice = createSlice({
    name: 'sortKey',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        change: (state, action: PayloadAction<OrderByKeys>) => {
            state.value = action.payload
        },
    },
})

export const { change } = sortKeySlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectSortKey = (state: RootState) => state.sortKey.value

export default sortKeySlice.reducer
