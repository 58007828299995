import { LoadingContainer } from 'components/loadingContainer'
import { ReactNode } from 'react'
import { useGetAuthConfigQuery } from 'services/configApi'

import { AuthNav } from './AuthNav'

export interface ConfigProviderProps {
    children: ReactNode
}

export const AuthConfigProvider = () => {
    const { data: authConfig, isLoading, isFetching, error } = useGetAuthConfigQuery()

    return (
        <LoadingContainer isLoading={isLoading || isFetching} error={error}>
            {authConfig ? (
                <AuthNav
                    authServerUrl={authConfig.authServerUrl}
                    realm={authConfig.realm}
                    clientId={authConfig.clientId}
                    userManagementWebApiUrl={authConfig.userManagementWebApiUrl}
                />
            ) : null}
        </LoadingContainer>
    )
}
