import { TFunction } from 'i18next'
import React from 'react'

export type ErrorCodes = 'required' | 'minLength' | 'maxLength' | 'duplicate' | 'emptyDefinition' | 'emptyFlightRules' | 'couldNotActivate'

export const errorCodeToMessage = (errCode: ErrorCodes | null): string => {
    switch (errCode) {
        case 'required':
            return 'error.requiredTitleError'
        case 'minLength':
            return 'error.shortTitleError'
        case 'maxLength':
            return 'error.longTitleError'
        case 'duplicate':
            return 'error.uniqueTitleError'
        case 'emptyDefinition':
            return 'error.emptyDefinition'
        case 'emptyFlightRules':
            return 'error.emptyFlightRules'
        case 'couldNotActivate':
            return 'error.couldNotActivate'
        default:
            return 'error.defaultError'
    }
}

export const wrapInErrorHandler = async (
    userAction: () => Promise<void>,
    t: TFunction,
    setError: (value: React.SetStateAction<string | undefined>) => void
) => {
    try {
        setError(undefined)
        await userAction()
    } catch (e: any) {
        setError(t(errorCodeToMessage(e?.data?.errorCode)))
    }
}
