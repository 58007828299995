export type SurveyExportErrorCodes = 'emptySchema'

export const errorCodeToMessage = (errCode: SurveyExportErrorCodes | null): string => {
    switch (errCode) {
        case 'emptySchema':
            return 'error.exportEmptySchemaError'
        default:
            return 'error.exportError'
    }
}
