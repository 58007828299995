import { ContentContainerTemplate } from '@control-tower/aerq-ui-library'
import { LoadingContainer } from 'components/loadingContainer'
import { useParams } from 'react-router-dom'
import { useGetFlightRuleManifestQuery } from 'services/ruleManifestApi'
import { useGetSurveyQuery } from 'services/surveyApi'
import { SurveyTimeTriggers } from 'types/enums/SurveyTimeTriggers'
import { SurveyCreatorUtils } from 'utils'

import { EditSurveyPage } from './EditSurveyPage'
import { useSurveyQuestionCount } from './hooks/useSurveyQuestionCount'

export const Page = () => {
    const { id }: { id?: string } = useParams()
    const surveyId = id || ''
    const { data: surveyData, error, isLoading, isFetching } = useGetSurveyQuery(surveyId, { refetchOnMountOrArgChange: true })
    const { data: ruleData } = useGetFlightRuleManifestQuery({ surveyId })
    const surveyQuestionCount = useSurveyQuestionCount(surveyData?.definition)

    if (isLoading || isFetching || !surveyData) {
        return <LoadingContainer isLoading={isLoading || isFetching} error={error} children={undefined} />
    }

    const { status, technicalName, responseCount, lastResponseDate, updatedAt, backgroundImage, surveyTypeConfig } = surveyData
    const timeTrigger = ruleData?.timeTrigger || ('30' as SurveyTimeTriggers)

    return (
        <ContentContainerTemplate className="contentContainer">
            <EditSurveyPage
                surveyQuestionCount={surveyQuestionCount}
                status={status}
                technicalName={technicalName}
                timeTrigger={timeTrigger}
                responseCount={responseCount || 0}
                lastResponseDate={lastResponseDate}
                updatedAt={updatedAt}
                backgroundImage={backgroundImage}
                surveyTypeConfig={surveyTypeConfig}
                supportedLanguages={SurveyCreatorUtils.getLanguageArray(surveyData)}
            />
        </ContentContainerTemplate>
    )
}
