import { Label, useWizardStepContext } from '@control-tower/aerq-ui-library'
import { SnackbarSuccessIcon } from '@control-tower/aerq-ui-library/dist/assets/svg'
import { useTranslation } from 'react-i18next'

import styles from './DoneStep.module.css'

export const DoneStep = () => {
    const { t } = useTranslation()
    const { data: currentStepData } = useWizardStepContext()

    return (
        <>
            <div className={styles.nameContainer}>
                <Label
                    className={styles.successMessage}
                    color="800"
                    type="grey"
                    title={`${currentStepData.technicalName} | ${t(`surveyType.${currentStepData.surveyType}`)}`}
                    variant="body"
                />
            </div>
            <div className={styles.iconContainer}>
                <SnackbarSuccessIcon width={94} height={94} />
            </div>
            <div className={styles.messageContainer}>
                <Label className={styles.successMessage} color="900" type="grey" title={t('success.provideInfo')} variant="body" />
            </div>
        </>
    )
}
