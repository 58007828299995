import { ExportHeader } from 'features/ExportSurvey'

import { AERQSurveyCreator } from './AERQSurveyCreator'

export const Page = () => {
    return (
        <>
            <ExportHeader />
            <AERQSurveyCreator />
        </>
    )
}
