import { CustomButton, Label } from '@control-tower/aerq-ui-library'
import { useNavigate } from 'react-router-dom'

import styles from './DetailsBlock.module.css'

export interface DetailsBlockProps {
    title: string
    col1Label: string
    col1Value: string | React.ReactNode
    col2Label: string
    col2Value: string | React.ReactNode
    col3Label?: string
    col3Value?: string | React.ReactNode
    hasButton?: boolean
    buttonTitle: string
    buttonLink: string
}

export const DetailsBlock = ({
    title,
    col1Label,
    col1Value,
    col2Label,
    col2Value,
    col3Label,
    col3Value,
    hasButton = true,
    buttonLink,
    buttonTitle,
}: DetailsBlockProps) => {
    const navigate = useNavigate()
    const isCol3Active = col3Label && col3Value

    return (
        <div>
            <Label color="900" type="grey" title={title} variant="h2" />
            <div className={`${styles.detailsTable} ${styles.twoColumn}`}>
                <Label color="600" type="grey" title={col1Label} variant="small" />
                <Label color="600" type="grey" title={col2Label} variant="small" />
                <Label color="800" type="grey" title={col1Value} variant="body" />
                <Label color="800" type="grey" title={col2Value} variant="body" />
            </div>
            {isCol3Active && (
                <div className={`${styles.detailsTable} ${styles.oneColumn}`}>
                    <Label color="600" type="grey" title={col3Label} variant="small" />
                    <Label color="800" type="grey" title={col3Value} variant="body" />
                </div>
            )}
            {hasButton && (
                <CustomButton variant="contained" color="primary" onClick={() => buttonLink.length && navigate(buttonLink)}>
                    {buttonTitle}
                </CustomButton>
            )}
        </div>
    )
}
