import { CustomButton, Label } from '@control-tower/aerq-ui-library'

export interface InputModalContentProps {
    isSubmitDisabled: boolean
    handleSubmit: () => void
    modalTitle: string | JSX.Element
    actionButtonLabel: string
    isError: boolean
    errorMessage?: string | JSX.Element
    description?: string | JSX.Element
}

/**
 * Simple modal content with tile + optional description.
 */
export const ModalContent = ({
    isSubmitDisabled,
    handleSubmit,
    modalTitle,
    actionButtonLabel,
    isError,
    errorMessage,
    description,
}: InputModalContentProps) => {
    const hasDescription = Boolean(!!description)
    const hasErrorMessage = Boolean(!!errorMessage)

    return (
        <>
            <div className="modalTitleContainer">
                <Label variant="h3">{modalTitle}</Label>
            </div>
            <div>
                <div className="modalInputContainer">
                    {hasDescription && <Label color="800" type="grey" title={description} variant="body" />}
                    {isError && hasErrorMessage && <div style={{ color: 'red', marginTop: 20 }}>{errorMessage}</div>}
                </div>

                <div className="modalButtonContainer">
                    <CustomButton disabled={isSubmitDisabled} variant="contained" color="primary" onClick={() => handleSubmit()}>
                        {actionButtonLabel}
                    </CustomButton>
                </div>
            </div>
        </>
    )
}
