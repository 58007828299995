import { FlightRules } from '../FlightRules'
import { NoFlightRules } from '../NoFlightRules'

interface Props {
    flightNumbers: string[]
    setIsEditing: (isEditing: boolean) => void
    readonly: boolean
    isFullBlownSurvey: boolean
}

export const FlightRulesContainer = ({ flightNumbers, setIsEditing, readonly, isFullBlownSurvey }: Props) => {
    return (
        <>
            {flightNumbers?.length ? (
                <FlightRules flightNumbers={flightNumbers} setIsEditing={setIsEditing} readonly={readonly} />
            ) : (
                <NoFlightRules setIsEditing={setIsEditing} readonly={readonly} isFullBlownSurvey={isFullBlownSurvey} />
            )}
        </>
    )
}
