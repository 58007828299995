// @ts-nocheck
import { CustomButton, Wizard, WizardStep } from '@control-tower/aerq-ui-library'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { AddNameStep } from './components/AddNameStep'
import { ChooseTypeStep } from './components/ChooseTypeStep'
import { DoneStep } from './components/DoneStep'

export const AddSurveyWizard = () => {
    const [isWizardOpen, setIsWizardOpen] = useState(false)
    const [createdSurveyId, setCreatedSurveyId] = useState<string | undefined>(undefined)
    const [selectedTypeTitle, setSelectedTypeTitle] = useState<SurveyType | undefined>(undefined)
    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <>
            <CustomButton variant="contained" color="primary" onClick={() => setIsWizardOpen(true)}>
                {t('action.addASurvey')}
            </CustomButton>

            {isWizardOpen && (
                <Wizard
                    isOpen={isWizardOpen}
                    nextLabel={t('action.next')}
                    previousLabel={t('action.previous')}
                    doneLabel={t('action.continue')}
                    doneStepNum={2}
                    lastStepPrevBtn={false}
                    closeBtn="hiddenOnSummary"
                    stepTitles={[
                        { num: 0, title: t('action.addASurvey') },
                        { num: 1, title: t(`createWizard.${selectedTypeTitle}`) },
                        { num: 2, title: t('success.surveyCreated') },
                    ]}
                    isAnimated={true}
                    handleClick={() => {
                        setIsWizardOpen(false)
                        if (createdSurveyId) {
                            navigate(`/edit-survey/${createdSurveyId}`)
                        }
                    }}
                    resetWizardOnClose={true}
                >
                    <WizardStep
                        num={0}
                        nextNum={1}
                        contentMargin={{ top: 0, left: 0, bottom: 0, right: 0 }}
                        hasPrevStepValidator={false}
                        hasNextStepValidator={true}
                    >
                        <ChooseTypeStep setSelectedTypeTitle={setSelectedTypeTitle} />
                    </WizardStep>
                    <WizardStep
                        num={1}
                        nextNum={2}
                        prevNum={0}
                        contentMargin={{ top: 0, left: 0, bottom: 0, right: 0 }}
                        hasPrevStepValidator={false}
                        hasNextStepValidator={true}
                    >
                        <AddNameStep setCreatedSurveyId={setCreatedSurveyId} />
                    </WizardStep>
                    <WizardStep
                        num={2}
                        prevNum={1}
                        hasPrevStepValidator={false}
                        contentMargin={{ top: 0, left: 0, bottom: 0, right: 0 }}
                        hasNextStepValidator={true}
                    >
                        <DoneStep />
                    </WizardStep>
                </Wizard>
            )}
        </>
    )
}
