import { theme } from '@control-tower/aerq-ui-library'
import { createTheme } from '@mui/material'

// override the default theme from aerq ui lib
export const appTheme = createTheme(
    {
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        color: '#000',
                    },
                },
            },
        },
    },
    theme
)
