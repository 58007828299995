import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { KeycloakService } from 'services/KeycloakService'
import { IAvailableFlightNumbers } from 'types/interfaces/IAvailableFLightNumber'
import { IGetAllQueryParam, ISurveyRuleManifest, ISurveyRuleManifestDocument } from 'types/interfaces/IRuleManifest'
import { ISurveyTimeTriggerItem } from 'types/interfaces/ISurveyTimeTriggerItem'

import { IDetailedConflictMap, ISurveyParam } from '../types/interfaces/SurveyConflict'

const keycloakService = KeycloakService.getInstance()

export const ruleManifestApi = createApi({
    reducerPath: 'ruleManifest',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/v1',
        prepareHeaders: async (headers) => {
            const keycloakInstance = keycloakService.getKeycloakInstance()

            if (keycloakInstance) {
                await keycloakInstance.updateToken(30)
                headers.set('Authorization', `Bearer ${keycloakInstance.token}`)
                headers.set('CT-ProfileId', keycloakService.getCtProfileId())
            }

            return headers
        },
    }),

    tagTypes: ['AvailableFlightNumbers', 'FlightRuleManifest', 'SurveyTimeTriggerOptions'],
    endpoints: (builder) => {
        return {
            getAvailableFlightNumbers: builder.query<IAvailableFlightNumbers, void>({
                query: () => '/rules/available-flight-numbers',
                providesTags: ['AvailableFlightNumbers'],
            }),
            getFlightRuleManifests: builder.query<ISurveyRuleManifestDocument[], IGetAllQueryParam>({
                query: (query) => {
                    let url = '/rules/rule-manifests'
                    const queryParams = []

                    if (query.surveyId) {
                        queryParams.push(`surveyId=${query.surveyId}`)
                    }

                    //Note: /rules/rule-manifest?surveyId=12&flightNumbers=F1&flightNumbers=F2
                    if (query.flightNumbers) {
                        for (const flightNumber of query.flightNumbers) {
                            queryParams.push(`flightNumbers=${flightNumber}`)
                        }
                    }

                    if (queryParams.length > 0) {
                        url += `?${queryParams.join('&')}`
                    }

                    return {
                        url,
                        method: 'GET',
                    }
                },
                providesTags: (result, error, query) => (error ? [] : [{ type: 'FlightRuleManifest', id: query.surveyId }]),
            }),
            getFlightRuleManifest: builder.query<ISurveyRuleManifestDocument, IGetAllQueryParam>({
                query: (query) => ({
                    url: `/rules/rule-manifest/survey/${query.surveyId}`,
                    method: 'GET',
                }),
                providesTags: (result, error, query) => (error ? [] : [{ type: 'FlightRuleManifest', id: query.surveyId }]),
            }),
            getFlightRuleConflicts: builder.query<IDetailedConflictMap, ISurveyParam>({
                query: (query) => {
                    let url = 'rules/rule-manifest/conflicts'

                    const queryParams = []

                    if (query.surveyId) {
                        queryParams.push(`surveyId=${query.surveyId}`)
                    }

                    if (query.flightNumbers) {
                        for (const flightNumber of query.flightNumbers) {
                            queryParams.push(`flightNumbers=${flightNumber}`)
                        }
                    }

                    if (queryParams.length > 0) {
                        url += `?${queryParams.join('&')}`
                    }

                    return {
                        url,
                        method: 'GET',
                    }
                },
            }),
            getSurveyTimeTriggerOptions: builder.query<ISurveyTimeTriggerItem[], void>({
                query: () => `/rules/time-trigger-options`,
                providesTags: ['SurveyTimeTriggerOptions'],
            }),
            // It can return an error like that:
            // {error: {data: { error: string, conflicts: IDetailedConflictMap}, status: number}}
            // but redux toolkit overwrites the type to be
            // {data: {error: {data: { error: string, conflicts: IDetailedConflictMap}, status: number}}}
            // and does not work out in real life
            upsertFlightRuleManifest: builder.mutation<{ conflicts: ISurveyRuleManifestDocument }, ISurveyRuleManifest>({
                query: (data) => ({
                    url: `/rules/rule-manifest`,
                    method: 'POST',
                    body: data,
                }),

                invalidatesTags: (result, error, query) => (error ? [] : [{ type: 'FlightRuleManifest', id: query.surveyId }]),
            }),
        }
    },
})

export const {
    useGetAvailableFlightNumbersQuery,
    useGetFlightRuleManifestsQuery,
    useUpsertFlightRuleManifestMutation,
    useLazyGetFlightRuleConflictsQuery,
    useGetFlightRuleManifestQuery,
    useLazyGetFlightRuleManifestQuery,
    useGetSurveyTimeTriggerOptionsQuery,
} = ruleManifestApi
