import { CheckboxList, CheckboxListItem, Label, useWizardState, useWizardStepContext } from '@control-tower/aerq-ui-library'
import { Plane } from '@control-tower/aerq-ui-library/dist/assets/svg'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetSurveyTypesQuery } from 'services/surveyApi'
import { SurveyType } from 'types/enums/SurveyType'
import { defaultLocale } from 'utils/i18n'

import styles from './ChooseTypeStep.module.css'
import { LoadingContainer } from './LoadingContainer'

export interface ChooseTypeStepProps {
    setSelectedTypeTitle: (surveyType: SurveyType) => void
}

export const ChooseTypeStep = ({ setSelectedTypeTitle }: ChooseTypeStepProps) => {
    const { t, i18n } = useTranslation()
    const [selectedValue, setSelectedValue] = useState<number | undefined>(undefined)
    const [selectedType, setSelectedType] = useState<SurveyType | undefined>(undefined)

    const { data: typeData, isError, isLoading } = useGetSurveyTypesQuery()
    const { wizardEventObserver, setActualStep, setStepData } = useWizardState()
    const { index } = useWizardStepContext()

    const formattedTypes = useMemo(
        () =>
            typeData?.map((type, i) => ({
                key: i + 1,
                surveyType: type.surveyType,
                // @ts-ignore
                title: type?.translations?.[i18n.language]?.title || type?.translations?.[defaultLocale]?.title,
                // @ts-ignore
                description: type?.translations?.[i18n.language]?.description || type?.translations?.[defaultLocale]?.description,
            })),
        [typeData, i18n]
    )

    useEffect(() => {
        const nextStepHandler = (_event: any, { actualStep }: any) => {
            if (actualStep !== index) return
            if (!selectedType) return // TODO add error message

            setStepData({ stepId: 1, data: { surveyType: selectedType } })
            setSelectedTypeTitle(selectedType)
            setActualStep(1)
        }

        // @ts-ignore
        wizardEventObserver.on('next', nextStepHandler)

        return () => {
            // @ts-ignore
            wizardEventObserver.remove('next', nextStepHandler)
        }
    }, [wizardEventObserver, setActualStep, index, setStepData, selectedType, setSelectedTypeTitle])

    return (
        <div className={styles.descriptionContainer}>
            <Label color="600" type="grey" title={t('action.chooseType')} variant="body" />
            <LoadingContainer isLoading={isLoading} isError={isError} error={t('error.couldNotGetTypes')}>
                <CheckboxList>
                    {formattedTypes?.map((type) => (
                        <CheckboxListItem
                            key={type.key}
                            value={type.key}
                            selectedValue={selectedValue}
                            title={type.title}
                            description={type.description}
                            icon={<Plane />}
                            onClick={() => {
                                setSelectedType(type.surveyType)
                                setSelectedValue(type.key)
                            }}
                        />
                    ))}
                </CheckboxList>
            </LoadingContainer>
        </div>
    )
}
