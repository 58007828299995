import { SnackbarOrigin, useSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useActivateSurveyMutation } from 'services/surveyApi'
import { SurveyType } from 'types/enums/SurveyType'
import { wrapInErrorHandler } from 'utils'
import { customAnchor, customSuccessSnackbar } from 'utils/customSnackbarContent'

import { SingleTypeModalContent } from './components/SingleTypeModalContent'

interface Props {
    id: string
    surveyType: SurveyType
    hideModal: () => void
    onError: (error: any) => void
}

export const SingleTypeSurveyModal = ({ id, surveyType, hideModal, onError }: Props) => {
    const [error, setError] = useState<string | undefined>(undefined)
    const [activateSurvey, { isLoading: isUpdating, isError }] = useActivateSurveyMutation()

    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const handleSubmit = async () => {
        wrapInErrorHandler(
            async () => {
                try {
                    await activateSurvey(id).unwrap()
                } catch (e: any) {
                    onError(e)
                    throw e
                }
                hideModal()
                enqueueSnackbar(t('success.publishSuccess'), {
                    content: customSuccessSnackbar,
                    anchorOrigin: customAnchor as SnackbarOrigin,
                })
            },
            t,
            setError
        )
    }

    return (
        <SingleTypeModalContent
            surveyType={surveyType}
            isSubmitDisabled={isUpdating}
            isError={isError}
            errorMessage={error}
            handleSubmit={handleSubmit}
            hideModal={hideModal}
        />
    )
}
