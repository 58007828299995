import { useQuery } from './useQuery'

/**
 * Calculates offset + limit for page numbers coming from search parameter `?p`
 *
 * @param limit how much data we should provide per page
 * @returns
 */
export function usePaginator(limit: number) {
    const query = useQuery()
    const page = query.get('p') || '1'
    const parsedPage = parseInt(page, 10)
    const offset = (parsedPage - 1) * limit

    return { offset, limit, page: parsedPage }
}
