export type OrderByKeys = 'last_changed' | 'alphabetical' | 'newest_first' | 'oldest_first' | 'survey_type'

export type OrderNetworkParams = {
    sortBy: 'technicalName' | 'updatedAt' | 'createdAt' | 'surveyType'
    order: 'asc' | 'desc'
}

/**
 * Mapping requirements (OrderByKeys) to actual network param object
 */
export const mapOrderByKeys = (key: OrderByKeys): OrderNetworkParams => {
    const lastChangedParams = {
        sortBy: 'updatedAt' as const,
        order: 'desc' as const,
    }

    switch (key) {
        case 'alphabetical':
            return {
                sortBy: 'technicalName',
                order: 'asc',
            }
        case 'last_changed':
            return lastChangedParams
        case 'newest_first':
            return {
                sortBy: 'createdAt',
                order: 'desc',
            }
        case 'oldest_first':
            return {
                sortBy: 'createdAt',
                order: 'asc',
            }
        case 'survey_type':
            return {
                sortBy: 'surveyType',
                order: 'asc',
            }
        default:
            return lastChangedParams
    }
}
