import { DateField } from '@control-tower/aerq-ui-library'
import { getDefaultDateFormatOptions } from 'utils/getDefaultDateFormatOptions'

export const formatDateWithFallback = (date: string, fallback = '/'): JSX.Element => {
    const hasDate = date?.length
    return hasDate ? (
        <DateField date={date} type="short" dateTodayLabel="Today" preferences={getDefaultDateFormatOptions()} />
    ) : (
        <>{fallback}</>
    )
}
